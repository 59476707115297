import {
    Box,
    Button,
    Dialog, List,
    TextField,
    useMediaQuery,
    useTheme,
    Alert,
    Link, ListItem, ListItemText, ListItemIcon
} from "@mui/material";
import {Check} from "@mui/icons-material";
import {integrationActions} from "../../../store/actions/integrationAction";
import {connect} from "react-redux";
import React, {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import Spinner from "../../spinner/Spinner";
import {integrationService} from "../../../service/integrationService";
import {alertActions} from "../../../store/actions/alertActions";
import {styled} from "@mui/system";

export const CustomListItem = styled(ListItem)(() => ({
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: 0
}));

export const CustomListItemText = styled(ListItemText)(() => ({
    "& .MuiTypography-root": {
        whiteSpace: "normal"
    }
}));

export const CustomListItemIcon = styled(ListItemIcon)(() => ({
    minWidth: 30
}));

function RevContentIntegration(props) {
    const theme = useTheme();
    const {integration, create, error, update, reset} = props;

    const integrationId = props?.match?.params?.id;

    const {register, handleSubmit, setValue, formState: {errors}} = useForm();
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }

        reset();
        setOpen(false);
        props.history.push({pathname: "/settings/integrations", state: {from: props.location}});
    }, [reset, props]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleOnSubmit = useCallback((data) => {
        if (integrationId > 0) {
            update(integrationId, data);
        } else {
            create(data);
        }
    });

    useEffect(() => {
        if (integration?.currentIntegration !== null) {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integration?.currentIntegration])

    useEffect(() => {
        async function find(id): void {
            setLoading(true);
            await integrationService.findIntegration(id).then((result) => {
                setValue("name", result?.networkName);
                for (const cred of result?.integrationCredentials) {
                    if (cred?.keyName === "revcontent_client_id") {
                        setValue("clientId", cred?.keyValue);
                    }
                    if (cred?.keyName === "revcontent_client_secret") {
                        setValue("clientSecret", cred?.keyValue);
                    }
                }
                setLoading(false);
            }).catch((e) => {
                setLoading(false);
                error(e?.response?.data?.error);
                setOpen(false);
                props.history.push({pathname: "/settings/integrations", state: {from: props.location}});
            });
        }

        if (integrationId > 0) {
            find(integrationId);
        }
    }, [error, integrationId, props.history, props.location, setValue]);

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                handleSubmit(handleOnSubmit)();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [handleOnSubmit, handleSubmit]);

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                maxWidth={"sm"}
                aria-labelledby="revcontent-integration"
        >
            <Box sx={{
                paddingTop: 1,
                paddingLeft: "15px",
                paddingRight: "15px",
                paddingBottom: "15px"
            }}>

                {(integration.loading.integration || loading) && <Spinner size={32} overComponentBox={true}/>}

                <Box sx={{
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    textAlign: "center",
                }}>
                    <img
                        src="/assets/images/traffic-sources/revcontent-white.svg" alt="Revcontent integration"
                        width={140}/>
                </Box>

                <p style={{paddingLeft: 10}}>
                    Please check our integration
                    guide <Link target="_blank" underline="hover" rel="noreferrer"
                                href="https://skro.eu/docs/integrations/revcontent-integration"
                >
                    here
                </Link>.
                </p>

                <form onSubmit={handleSubmit(handleOnSubmit)} autoComplete={"off"}>
                    <Box display="flex" flexWrap={"wrap"}>
                        <Box p={1} width="100%">
                            <TextField
                                error={!!errors.name}
                                variant="outlined"
                                margin="dense"
                                id="name"
                                label="Integration Name"
                                {...register("name", {required: true, maxLength: 255, minLength: 2})}
                                name="name"
                                size="small"
                                type="text"
                                placeholder="Enter your integration name"
                                fullWidth={true}
                                defaultValue="RevContent"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        <Box p={1} width="100%">
                            <TextField
                                error={!!errors.clientId}
                                variant="outlined"
                                margin="dense"
                                id="clientId"
                                label="Client ID"
                                {...register("clientId", {required: true, maxLength: 1024, minLength: 2})}
                                name="clientId"
                                size="small"
                                type="text"
                                placeholder="Enter your Client ID"
                                fullWidth={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        <Box p={1} width="100%">
                            <TextField
                                error={!!errors.clientSecret}
                                variant="outlined"
                                margin="dense"
                                id="clientSecret"
                                label="Client Secret"
                                {...register("clientSecret", {required: true, maxLength: 1024, minLength: 2})}
                                name="clientSecret"
                                size="small"
                                type="text"
                                placeholder="Enter your Client Secret"
                                fullWidth={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </Box>
                </form>

                <Box display="flex" flexDirection="row" ml={1}>
                    <Box>
                        <List>
                            <CustomListItem>
                                <CustomListItemIcon>
                                    <Check color={"primary"}/>
                                </CustomListItemIcon>
                                <CustomListItemText>
                                    Sync cost for campaigns, widgets and contents every 30 min.
                                </CustomListItemText>
                            </CustomListItem>
                            <CustomListItem>
                                <CustomListItemIcon>
                                    <Check color={"primary"}/>
                                </CustomListItemIcon>
                                <CustomListItemText>
                                    Check status of your campaigns, widgets and contents.
                                </CustomListItemText>
                            </CustomListItem>
                            <CustomListItem>
                                <CustomListItemIcon>
                                    <Check color={"primary"}/>
                                </CustomListItemIcon>
                                <CustomListItemText>
                                    Pause or resume campaigns, widgets and contents.
                                </CustomListItemText>
                            </CustomListItem>
                            <CustomListItem>
                                <CustomListItemIcon>
                                    <Check color={"primary"}/>
                                </CustomListItemIcon>
                                <CustomListItemText>
                                    Change bid value for campaigns and widgets.
                                </CustomListItemText>
                            </CustomListItem>
                            <CustomListItem>
                                <CustomListItemIcon>
                                    <Check color={"primary"}/>
                                </CustomListItemIcon>
                                <CustomListItemText>
                                    Change daily budget for campaigns.
                                </CustomListItemText>
                            </CustomListItem>
                        </List>
                    </Box>
                </Box>

                <Box display="flex" ml={1} mt={1}>
                    <Box flexGrow={1}>
                        <Alert variant="filled" severity="warning">Don't forget to associate it with your traffic
                            source.</Alert>
                    </Box>
                </Box>

                <Box display="flex" justifyContent="flex-end" mt={2}>
                    <Box mr={2}>
                        <Button onClick={handleClose} color="inherit" variant="outlined">
                            Cancel
                        </Button>
                    </Box>
                    <Box>
                        <Button onClick={handleSubmit(handleOnSubmit)} color="primary" variant="contained">
                            {integrationId > 0 ? "Update Integration" : "Create Integration"}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        integration: state.integration
    };
};

const mapDispatchToProps = {
    reset: integrationActions.reset,
    create: integrationActions.createRevContentIntegration,
    update: integrationActions.updateRevContentIntegration,
    error: alertActions.error
};

export default connect(mapStateToProps, mapDispatchToProps)(RevContentIntegration);