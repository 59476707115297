import {
    Alert, Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel,
    FormHelperText, Radio, RadioGroup, Switch,
    TextField, IconButton, useTheme,
} from "@mui/material";
import Spinner from "../spinner/Spinner";
import React, {useEffect, useState} from "react";
import CountrySelect from "../utils/CountrySelect";
import AffiliateNetworkSelect from "./AffiliateNetworkSelect";
import CurrencySelect from "./CurrencySelect";
import {Controller} from "react-hook-form";
import CopyContentToClipboard from "../utils/CopyContentToClipboard";
import UrlWithParams from "../input/UrlWithParams";
import {sharedAffiliateNetworkService} from "../../service/sharedAffiliateNetworkService";
import {GetDefaultTrackingDomain, GetPostbackLink, GetReplacedPostbackDomainLink} from "../../helpers/common";
import AffiliateNetworkPopUp from "../affiliate-networks/AffiliateNetworkPopUp";
import {Add, Info} from "@mui/icons-material";
import {RenderShopifyPostbackScript} from "../campaigns/CampaignLinks";
import TimezoneSelect from "../timezone/TimezoneSelect";
import SelectOffer from "./SelectOffer";
import WorkspaceDropdown from "../workspace/WorkspaceDropdown";
import ReactHookTagsSelect from "../tags/ReactHookTagsSelect";
import {CustomTooltip} from "../landings/LandingForm";

export const OfferForm = ({
                              title,
                              watch,
                              control,
                              register,
                              handleClose,
                              requestError,
                              errors,
                              handleSubmit,
                              onSubmit,
                              loading,
                              setValue,
                              getValues,
                              offerData
                          }) => {
    const theme = useTheme();

    const [sharedAffiliateNetwork, setSharedAffiliateNetwork] = useState(null);
    const [postbackLink, setPostbackLink] = useState(GetReplacedPostbackDomainLink());
    const [openAffNetworkPopUp, setOpenAffNetworkPopUp] = useState(false);

    const watchAffNetwork = watch("affiliateNetwork");

    useEffect(() => {

        async function fetch(id, protectionToken) {

            const response = await sharedAffiliateNetworkService.find(id);
            if (response?.id > 0) {
                setTimeout(() => {
                    setSharedAffiliateNetwork(response);
                    let link = GetPostbackLink(
                        response?.clickIdPlaceholder,
                        response?.payoutPlaceholder,
                        response?.transactionIdPlaceholder,
                        response?.statusPlaceholder
                    );

                    if (protectionToken !== "" && protectionToken !== undefined) {
                        link = link + "&protectionToken=" + protectionToken;
                    }

                    setPostbackLink(link);
                });
            }
        }

        if (watchAffNetwork !== undefined && watchAffNetwork !== null) {
            if (sharedAffiliateNetwork === null && watchAffNetwork?.sharedAffiliateNetworkId !== null) {
                fetch(watchAffNetwork?.sharedAffiliateNetworkId, watchAffNetwork?.postbackProtectionToken);
            }

            if (sharedAffiliateNetwork !== null && sharedAffiliateNetwork?.id !== watchAffNetwork?.sharedAffiliateNetworkId && watchAffNetwork?.sharedAffiliateNetworkId !== null) {
                fetch(watchAffNetwork?.sharedAffiliateNetworkId, watchAffNetwork?.postbackProtectionToken);
            }

            if (watchAffNetwork?.sharedAffiliateNetworkId === null) {
                if (watchAffNetwork?.usePostbackProtection) {
                    setPostbackLink(GetPostbackLink() + "&protectionToken=" + watchAffNetwork?.postbackProtectionToken);
                } else {
                    setPostbackLink(GetPostbackLink());
                }
            }
        }

    }, [sharedAffiliateNetwork, watchAffNetwork]);

    const handleCloseAffNetworkPopUp = () => {
        setOpenAffNetworkPopUp(false);
    };

    const handleClickOpenAffNetworkPopUp = () => {
        setOpenAffNetworkPopUp(true);
    };

    return (
        <>
            <DialogTitle sx={{textAlign: "center"}}>{title}</DialogTitle>
            <DialogContent>
                {requestError.length > 1 &&
                    <Alert sx={{marginBottom: "10px"}} variant="filled" severity="error">
                        {requestError}
                    </Alert>
                }
                <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"} style={{minHeight: "300px"}}>
                    <Box display="flex" mb={1}>
                        <Box flexGrow={1}>
                            <WorkspaceDropdown
                                errors={errors} control={control} workspaceId={offerData?.workspaceId}
                                setValue={setValue}
                            />
                        </Box>
                        <Box p={1}>
                            <Box sx={{paddingTop: "10px"}}>
                                <CustomTooltip
                                    disableFocusListener
                                    title="Workspace allows to make the required offer accessible only by a specific user or group of users."
                                >
                                    <Info color={"primary"} fontSize={"small"}/>
                                </CustomTooltip>
                            </Box>
                        </Box>
                    </Box>

                    <TextField
                        error={!!errors.name}
                        variant="outlined"
                        margin="dense"
                        id="name"
                        size="small"
                        {...register("name", {required: true, maxLength: 255, minLength: 3})}
                        name="name"
                        label="Name*"
                        placeholder="Offer name"
                        type="text"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <FormHelperText>
                        {watch("country") !== undefined && watch("country") !== null ? watch("country").label : "Global"} - {watch("name")}
                    </FormHelperText>

                    <UrlWithParams
                        label="Offer URL*"
                        name="url"
                        register={register}
                        errors={errors}
                        watch={watch}
                        setValue={setValue}
                    />

                    <Box display="flex" flexDirection="column">
                        <Box pt={1} flexGrow={1}>
                            <ReactHookTagsSelect
                                control={control}
                                inputName="tags"
                                inputLabel="Tags"
                                inputLabelShrink={true}
                                getValues={getValues}
                            />
                        </Box>
                        <Box flexGrow={1} pt={1} sx={{maxWidth: 390}}>
                            <CountrySelect
                                control={control}
                                title="Country select is required to navigate easily through the offers list. This selection does not affect the traffic distribution."
                            />
                        </Box>
                    </Box>

                    <Box display="flex" mb={1} mt={1} flexWrap="nowrap">
                        <Box flexGrow={1} sx={{maxWidth: 350}}>
                            <AffiliateNetworkSelect control={control}/>
                        </Box>
                        <Box ml={0.5} mt={1.6}>
                            <CustomTooltip title="Create a new affiliate network">
                                <IconButton color="primary" size="small" onClick={handleClickOpenAffNetworkPopUp}>
                                    <Add fontSize="small"/>
                                </IconButton>
                            </CustomTooltip>
                            <AffiliateNetworkPopUp
                                open={openAffNetworkPopUp}
                                onClose={handleCloseAffNetworkPopUp}
                            />
                        </Box>
                    </Box>

                    {watch("affiliateNetwork")?.label?.toLowerCase().includes("shopify")
                        ?
                        <Box display="flex" flexDirection="column" mb={3}>
                            <Box>
                                <RenderShopifyPostbackScript trackingDomain={GetDefaultTrackingDomain()}/>
                            </Box>
                        </Box>
                        :
                        <Box display="flex" flexDirection="column" mb={3}>
                            <Box>
                                <CopyContentToClipboard label="Postback URL" copyText={postbackLink}/>
                            </Box>
                        </Box>
                    }

                    <Box sx={{fontSize: 16, fontWeight: 600, pb: 1}}>RSOC Channel/Campaign ID</Box>
                    <Box display="flex" flexDirection="row" pb={2}>
                        <Box flexGrow={1} sx={{maxWidth: 350}}>
                            <TextField
                                error={!!errors.channelId}
                                variant="outlined"
                                margin="dense"
                                id="channelId"
                                label="RSOC Channel/Campaign ID"
                                {...register("channelId", {maxLength: 100, minLength: 1})}
                                name="channelId"
                                size="small"
                                type="text"
                                placeholder="Enter your RSOC Channel/Campaign ID"
                                fullWidth={true}
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        <Box sx={{pt: "18px", pl: 1}}>
                            <CustomTooltip
                                title="Define your RSOC channel or campaign id to track revenue. Supported networks: Ads.com, Predicto.ai">
                                <Info color={"primary"} fontSize={"small"}/>
                            </CustomTooltip>
                        </Box>
                    </Box>

                    <PayoutType
                        register={register}
                        errors={errors}
                        offerData={offerData}
                        control={control}
                        watch={watch}
                        setValue={setValue}
                    />

                    <Box display="flex" flexDirection="column" mt={1}>
                        <Box mt={1}>
                            <EnableDailyCap
                                watch={watch}
                                theme={theme}
                                setValue={setValue}
                                register={register}
                                errors={errors}
                                offerData={offerData}
                                control={control}
                            />
                        </Box>
                    </Box>
                    <input type="submit" hidden="hidden"/>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit" variant="outlined">
                    Cancel
                </Button>
                {loading ? <Spinner/> :
                    <Button onClick={handleSubmit(onSubmit)} color="primary" variant="contained">
                        Save & Close
                    </Button>
                }
            </DialogActions>
        </>
    );
};

function PayoutType({control, register, errors, watch}) {
    return (
        <>
            <Box sx={{fontSize: 16, fontWeight: 600, pb: 1}}>Payout Type</Box>
            <Box display="flex" flexDirection="row" flexWrap="wrap" sx={{marginTop: "-8px"}}>
                <Box mt={1} mb={1}>
                    <Controller
                        render={({field: {onChange, value, ...props}}) => (
                            <RadioGroup
                                row
                                aria-label="payoutType"
                                defaultValue="auto"
                                value={value}
                                onChange={(e) => onChange(e.target.value)}
                            >
                                <FormControlLabel
                                    value="auto"
                                    control={<Radio color="primary"/>}
                                    label="Auto"
                                />
                                <FormControlLabel
                                    value="manual"
                                    control={<Radio color="primary"/>}
                                    label="Manual"
                                />
                            </RadioGroup>
                        )}
                        control={control}
                        name="payoutType"
                        defaultValue="auto"
                    />
                </Box>
                {watch("payoutType") === "manual" &&
                    <Box sx={{maxWidth: 100}} mr={2}>
                        <TextField
                            error={!!errors.payoutSum}
                            variant="outlined"
                            margin="dense"
                            id="payoutSum"
                            name="payoutSum"
                            size="small"
                            {...register("payoutSum", {
                                required: true,
                                pattern: /^(\d+)(?:[.]\d+)?$/
                            })}
                            label="Payout Sum"
                            placeholder="2.50"
                            type="text"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                }
                <Box sx={{minWidth: 200}}>
                    <CurrencySelect control={control}/>
                </Box>
            </Box>
        </>
    );
}

function EnableDailyCap({setValue, control, register, errors, offerData, watch, theme}) {
    return (
        <>
            <Controller
                name="enableDailyCap"
                control={control}
                render={({field: {onChange, value, ...props}}) => (
                    <FormControlLabel
                        control={
                            <Switch
                                onChange={(e) => onChange(e.target.checked)}
                                checked={value}
                                color="primary"
                            />
                        }
                        label={(
                            <span>
                                <span>Enable Daily Cap (24h)</span>
                                <CustomTooltip
                                    disableFocusListener
                                    title="Use this option if you want to set up a daily cap for that offer.
                                         Before you specify the daily cap value it is required to set up the offer you
                                         wish to promote instead after the limit on initial offer is reached. Make sure you
                                         have set up the correct postback between Skro and affiliate network / direct
                                         advertizer associated with this offer."
                                >
                                    <Info color={"primary"} fontSize={"small"}/>
                                </CustomTooltip>
                            </span>
                        )}
                    />)}
                defaultValue={false}
            />

            {watch("enableDailyCap") && <>
                <Box display="flex" flexDirection="column" mt={1}>
                    <Box display="flex" width="100%" flexWrap="wrap">
                        <Box display="flex" flexWrap="nowrap" mr={2} flex={2} flexGrow={1}>
                            <Box sx={{minWidth: 120, maxWidth: 120, width: "100%"}}>
                                <TextField
                                    error={!!errors.dailyCapLimit}
                                    variant="outlined"
                                    margin="dense"
                                    id="dailyCapLimit"
                                    {...register("dailyCapLimit", {
                                        required: true,
                                        maxLength: 12,
                                        minLength: 1,
                                        pattern: /^\d*?$/
                                    })}
                                    name="dailyCapLimit"
                                    label="Cap limit"
                                    placeholder="e.g. 100"
                                    type="number"
                                    size="small"
                                    sx={{width: "100%"}}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box display="flex" flexWrap="nowrap" flex={8} flexGrow={3}
                             sx={{[theme.breakpoints.up(663)]: {marginRight: "6px"}}}>
                            <Box sx={{minWidth: 200, width: "100%"}}>
                                <TimezoneSelect control={control} selectedValue={offerData?.dailyCapTimezone}
                                                setValue={setValue}/>
                            </Box>
                            <Box p={1}>
                                <Box sx={{paddingTop: "10px"}}>
                                    <CustomTooltip
                                        disableFocusListener
                                        title="The timezone in daily cap settings should be matching the timezone
                                             of your affiliate network or direct advertizer. If the zones are not
                                             synchronized, the daily cap option will not work correctly."
                                    >
                                        <Info color={"primary"} fontSize={"small"}/>
                                    </CustomTooltip>
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex" flexWrap="nowrap" flex={10} flexGrow={3}>
                            <Box sx={{minWidth: 200, width: "100%"}}>
                                <SelectOffer control={control} errors={errors} offerData={offerData}
                                             setValue={setValue}/>
                            </Box>
                            <Box p={1}>
                                <Box sx={{paddingTop: "10px"}}>
                                    <CustomTooltip
                                        disableFocusListener
                                        title="Select the offer to redirect your traffic, when the main offer
                                             daily limit of conversions has been reached."
                                    >
                                        <Info color={"primary"} fontSize={"small"}/>
                                    </CustomTooltip>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </>
            }
        </>
    );
}