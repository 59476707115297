import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {connect} from "react-redux";
import {trafficSourceActions} from "../../store/actions/trafficSourceAction";
import {TrafficSourceForm} from "./TrafficSourceForm";
import {trafficSourceServices} from "../../service/trafficSourceService";
import {TrafficSourceTemplates} from "./TrafficSourceTemplates";
import {Dialog, useMediaQuery, useTheme} from "@mui/material";

function AddTrafficSource(props) {

    const [open, setOpen] = useState(true);
    const [formData, setFormData] = useState(null);
    const [requestError, setRequestError] = useState("");
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState("templates");
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [formSuccessfullySubmitted, setFormSuccessfullySubmitted] = useState(false);

    const {register, handleSubmit, setValue, setError, watch, control, getValues, formState: {errors}} = useForm();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        setOpen(false);
        props.history.push({pathname: "/traffic-sources", state: {from: props.location}});
    }, [props]);

    useEffect(() => {
        async function postData(): void {
            try {
                setRequestError("");
                setLoading(true);
                const response = await trafficSourceServices.create(formData);
                if (response.hasOwnProperty("id") && response.id > 0) {
                    props.rerender();
                    setFormSuccessfullySubmitted(true);
                }
            } catch (error) {
                if (error?.response?.data?.error !== undefined) {
                    setRequestError(error.response.data.error);
                }
            } finally {
                setLoading(false);
            }
        }

        if (formData !== null) {
            postData();
        }

    }, [formData, props]);

    useEffect(() => {
        if (formSuccessfullySubmitted) {
            handleClose();
        }
    }, [formSuccessfullySubmitted, handleClose]);

    const handleSelectedTemplate = (data) => {
        setSelectedTemplate(data);
        setStep("custom");
    };

    useEffect(() => {
        if (selectedTemplate !== undefined && selectedTemplate !== null) {
            setTimeout(() => {
                for (const [key, value] of Object.entries(selectedTemplate)) {
                    setValue(key, value);
                }
                setValue("workspace", {value: 0, label: ""});
            }, 100);
        }
    }, [setValue, selectedTemplate, open]);

    const onSubmit = (data) => {

        if (data?.workspace?.value === 0) {
            setError("workspace", {
                type: "manual",
                message: "Please select workspace."
            });
            return false;
        }

        if (data.usePostback && data?.postbackUrl.includes("REPLACE")) {
            setError("postbackUrl", {
                type: "manual",
                message: "Please replace parameter values where needed."
            });
            return false;
        }

        // Add traffic source integration
        if (data?.integrationId?.value) {
            data.integrationId = data?.integrationId?.value;
        }

        // Add workspace id
        data.workspaceId = data?.workspace?.value;
        data.tags = data?.tags ? data?.tags : [];

        // modify postbackPercentage int to string
        data.postbackPercentage = data?.postbackPercentage ? data?.postbackPercentage.toString() : ""

        // Map selectedCustomConversions as comma-separated list.
        if (data?.selectedCustomConversions !== "" && data?.selectedCustomConversions !== undefined) {
            let selectedCustomConversion = ""
            // eslint-disable-next-line array-callback-return
            data?.selectedCustomConversions?.map((item) => {
                if (selectedCustomConversion === "") {
                    selectedCustomConversion = item?.id;
                } else {
                    selectedCustomConversion += ", " + item?.id;
                }
            });
            data.selectedCustomConversions = selectedCustomConversion.toString();
        }

        setFormData(data);
    };

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                maxWidth={"md"}
                aria-labelledby="form-dialog-title"
        >
            {step === "templates" && <TrafficSourceTemplates
                handleClose={handleClose}
                handleSelectedTemplate={handleSelectedTemplate}
            />}

            {step === "custom" && <TrafficSourceForm
                title="New traffic source"
                register={register}
                handleClose={handleClose}
                requestError={requestError}
                errors={errors}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                loading={loading}
                setValue={setValue}
                watch={watch}
                trafficSourceData={selectedTemplate}
                control={control}
                setRequestError={setRequestError}
                getValues={getValues}
            />}
        </Dialog>
    );
}

const mapDispatchToProps = {
    rerender: trafficSourceActions.rerender
};

export default connect(null, mapDispatchToProps)(AddTrafficSource);
