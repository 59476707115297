import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {connect} from "react-redux";
import {affiliateNetworkActions} from "../../store/actions/affiliateNetworkAction";
import {affNetworkServices} from "../../service/affiliateNetworkService";
import {AffiliateNetworkForm} from "./AffiliateNetworkForm";
import {GetPostbackLink} from "../../helpers/common";
import {postbackURL} from "../../helpers/config";
import {Dialog, useMediaQuery, useTheme} from "@mui/material";

function EditAffiliateNetwork(props) {

    const [open, setOpen] = useState(true);
    const [formData, setFormData] = useState(null);
    const [affNetworkData, setAffNetworkData] = useState(null);
    const [requestError, setRequestError] = useState("");
    const [loading, setLoading] = useState(false);
    const [postbackLink, setPostbackLink] = useState(postbackURL);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const {register, handleSubmit, setValue, watch, control, getValues, formState: {errors}} = useForm();

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        setOpen(false);
        props.history.push({pathname: "/affiliate-networks", state: {from: props.location}});
    }, [props.history, props.location]);

    const onSubmit = (data) => {
        // add workspace id value
        data.workspaceId = data?.workspace?.value;
        setFormData(data);
    };

    useEffect(() => {
        async function findAffiliateNetworkById(id) {
            setRequestError("");
            setLoading(true);
            await affNetworkServices.find(id).then((response) => {
                if (response?.id > 0) {
                    setAffNetworkData(response);
                }
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    setRequestError(error.response.data.error);
                }
            }).finally(() => {
                setLoading(false);
            });
        }

        if (props?.match?.params?.id && affNetworkData === null) {
            findAffiliateNetworkById(props.match.params.id);
        }

    }, [affNetworkData, props]);

    useEffect(() => {
        if (affNetworkData !== null) {
            setValue("name", affNetworkData?.name);
            setValue("tags", affNetworkData?.tags);
            setValue("postbackOnlyWhiteIps", affNetworkData?.postbackOnlyWhiteIps);
            setValue("allowDoubleConversions", affNetworkData?.allowDoubleConversions);
            setValue("useConversionVisitTime", affNetworkData?.useConversionVisitTime);
            setValue("usePostbackStatuses", affNetworkData?.usePostbackStatuses);
            setValue("usePostbackProtection", affNetworkData?.usePostbackProtection);

            setPostbackLink(
                GetPostbackLink(
                    affNetworkData?.sharedAffiliateNetwork?.clickIdPlaceholder,
                    affNetworkData?.sharedAffiliateNetwork?.payoutPlaceholder,
                    affNetworkData?.sharedAffiliateNetwork?.transactionIdPlaceholder,
                    affNetworkData?.sharedAffiliateNetwork?.statusPlaceholder
                )
            );

            setTimeout(() => {
                setValue("postbackWhiteIps", affNetworkData?.postbackWhiteIps);
                setValue("postbackStatusApproved", affNetworkData?.postbackStatusApproved);
                setValue("postbackStatusPending", affNetworkData?.postbackStatusPending);
                setValue("postbackStatusDeclined", affNetworkData?.postbackStatusDeclined);
                setValue("postbackStatusRefund", affNetworkData?.postbackStatusRefund);
                setValue("postbackProtectionToken", affNetworkData?.postbackProtectionToken);
            });
        }
    }, [affNetworkData, setValue]);

    useEffect(() => {
        let id = 0;
        if (props?.match?.params?.id) {
            id = props.match.params.id;
        }

        async function editData() {
            setRequestError("");
            setLoading(true);
            await affNetworkServices.update(id, formData).then((response) => {
                if (response?.id > 0) {
                    props.rerender(true);
                    setLoading(false);
                    handleClose();
                } else {
                    setLoading(false);
                }
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    setRequestError(error.response.data.error);
                }
                setLoading(false);
            });
        }

        if (formData !== null) {
            editData();
        }

    }, [handleClose, formData, props]);

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                maxWidth={"md"}
                aria-labelledby="form-dialog-title"
        >
            <AffiliateNetworkForm
                title="Update affiliate network"
                handleClose={handleClose}
                requestError={requestError}
                onSubmit={onSubmit}
                control={control}
                register={register}
                errors={errors}
                handleSubmit={handleSubmit}
                loading={loading}
                postbackLink={postbackLink}
                watch={watch}
                formData={affNetworkData}
                getValues={getValues}
                setValue={setValue}
            />
        </Dialog>
    );
}

const mapDispatchToProps = {
    rerender: affiliateNetworkActions.rerender
};

export default connect(null, mapDispatchToProps)(EditAffiliateNetwork);