import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Drawer, IconButton,
    List,
    ListItem, ListItemSecondaryAction,
    ListItemText,
    Paper, TextField, Tooltip,
    Typography, useTheme
} from "@mui/material";
import {Add, Close, Delete, Edit} from "@mui/icons-material";
import {useForm} from "react-hook-form";
import {noteServices} from "../../service/noteService";
import {connect} from "react-redux";
import {alertActions} from "../../store/actions/alertActions";
import Spinner from "../spinner/Spinner";
import moment from "moment";

function NotesForm(props) {
    const {open, toggleDrawer123, campaignId} = props;
    const theme = useTheme();
    const {handleSubmit, setValue, reset, register, formState: {errors}} = useForm();

    const [notes, setNotes] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentCampaignId, setCurrentCampaignId] = useState(0);
    const [editNote, setEditNote] = useState(null);

    const toggleDrawer = (open) => () => {
        toggleDrawer123(open);
    };

    const toggleShowForm = (show) => {
        setShowForm(show);
    };

    const handleOnClickEditNote = (id, note) => {
        setShowForm(true);
        window.scrollTo(0, 0);
        setEditNote({id, note});
    };

    const handleOnClickSave = (formData) => {
        setLoading(true);
        noteServices.create({campaignId, formData}).then(async () => {
            props.successMsg("Added successfully.");
            toggleShowForm(false);
            await noteServices.read({campaignId}).then((resp) => {
                setNotes(resp?.notes);
            });
            reset();
        }).catch((e) => {
            props.errorMsg(e?.response?.data?.error);
        }).finally(() => {
            setLoading(false);
        });
    };

    const handleOnClickUpdate = (formData) => {
        setLoading(true);
        noteServices.update({campaignId, noteId: editNote?.id, formData}).then(async () => {
            setEditNote(null);
            toggleShowForm(false);
            props.successMsg("Updated successfully.");
            await noteServices.read({campaignId}).then((resp) => {
                setNotes(resp?.notes);
            });
            reset();
        }).catch((e) => {
            props.errorMsg(e?.response?.data?.error);
        }).finally(() => {
            setLoading(false);
        });
    };

    const handleOnClickDelete = (noteId) => {
        setLoading(true);
        noteServices.archive({campaignId, noteId}).then(() => {
            props.successMsg("Deleted successfully.");
            let newNotes = notes.filter((item) => item.id !== noteId);
            setNotes(newNotes);
        }).catch((e) => {
            props.errorMsg(e?.response?.data?.error);
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        async function fetchNotes() {
            setLoading(true);
            noteServices.read({campaignId}).then((resp) => {
                setNotes(resp?.notes);
            }).catch((e) => {
                props.errorMsg(e?.response?.data?.error);
            }).finally(() => {
                setLoading(false);
            });
        }

        if (notes === null) {
            setCurrentCampaignId(campaignId);
            fetchNotes();
        } else if (campaignId !== currentCampaignId) {
            setCurrentCampaignId(campaignId);
            fetchNotes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignId, notes]);

    useEffect(() => {
        if (editNote !== null && editNote?.id > 0) {
            setValue("note", editNote?.note);
        }
    }, [setValue, editNote]);

    return (
        <Drawer
            anchor={"right"}
            open={open}
            onClose={toggleDrawer(false)}
            hideBackdrop={true}
            sx={{
                position: "unset",
                "& .MuiPaper-root-MuiDrawer-paper": {
                    inset: "unset !important",
                },
                "& .MuiDrawer-paper": {
                    top: "unset",
                    bottom: 0,
                    maxHeight: 600,
                    backgroundColor: "background.darkBlue",
                },
                "& .MuiPaper-root": {
                    backgroundColor: "background.darkBlue",
                }
            }}
        >
            <Box sx={{
                width: 350,
                [theme.breakpoints.down(500)]: {
                    width: "100% !important",
                    maxWidth: 350
                },
                padding: 1,
            }}>
                {loading && <Spinner overComponentBox={true}/>}
                <Paper elevation={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1}>
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: 18,
                                    paddingTop: 1,
                                    paddingBottom: 2,
                                    paddingLeft: 1
                                }}
                                variant="h3"
                            >
                                Notes
                            </Typography>
                        </Box>
                        <Box mt={0.5}>
                            <IconButton
                                onClick={toggleDrawer(false)}
                                variant="text"
                                color="inherit"
                                size="small"
                                sx={{
                                    "&:hover": {
                                        color: "error.main"
                                    }
                                }}
                            >
                                <Close/>
                            </IconButton>
                        </Box>
                    </Box>
                    {!showForm &&
                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" pt={0}
                             pb={1.5}>
                            <Button
                                onClick={() => toggleShowForm(true)}
                                variant="text"
                                color="primary"
                                startIcon={<Add/>}
                            >
                                Add Note
                            </Button>
                        </Box>
                    }
                    {showForm &&
                        <Box display="flex" flexDirection="column" p={1}>
                            <Box flexGrow={1}>
                                <TextField
                                    error={!!errors.note}
                                    autoFocus
                                    variant="outlined"
                                    margin="dense"
                                    id="note"
                                    {...register("note", {required: true, maxLength: 255, minLength: 3})}
                                    name="note"
                                    size="small"
                                    type="text"
                                    placeholder="Enter your note..."
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                            <Box display="flex" flexDirection="row" justifyContent="flex-end" pt={1}>
                                <Box>
                                    <Button
                                        onClick={() => toggleShowForm(false)}
                                        variant="outlined"
                                        color="inherit"
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                                <Box pl={2}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={editNote !== null ? handleSubmit(handleOnClickUpdate) : handleSubmit(handleOnClickSave)}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    }
                    <List>
                        {notes?.map(({id, note, createdAt}) => (
                            <React.Fragment key={id}>
                                <ListItem
                                    key={id}
                                    sx={{
                                        backgroundColor: "background.paper",
                                        borderRadius: 20,
                                        marginBottom: 1,
                                        paddingRight: "75px"
                                    }}
                                >
                                    <ListItemText primary={moment(createdAt).format("YYYY-MM-DD HH:mm:ss")}
                                                  secondary={note}/>
                                    <ListItemSecondaryAction>
                                        <Tooltip title={"Edit"} placement="top">
                                                <span>
                                                    <IconButton
                                                        onClick={() => handleOnClickEditNote(id, note)}
                                                        variant="text"
                                                        color="inherit"
                                                        size="small"
                                                        sx={{
                                                            "&:hover": {
                                                                color: "text.secondary"
                                                            }
                                                        }}
                                                    >
                                                        <Edit fontSize="small" sx={{p: "3px"}}/>
                                                    </IconButton>
                                                </span>
                                        </Tooltip>
                                        <Tooltip title={"Delete"} placement="top">
                                                <span>
                                                    <IconButton
                                                        onClick={() => handleOnClickDelete(id)}
                                                        variant="text"
                                                        color="inherit"
                                                        size="small"
                                                        sx={{
                                                            "&:hover": {
                                                                color: "error.main"
                                                            }
                                                        }}
                                                    >
                                                        <Delete fontSize="small" sx={{p: "3px"}}/>
                                                    </IconButton>
                                                </span>
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </React.Fragment>
                        ))}
                    </List>
                </Paper>
            </Box>
        </Drawer>
    );
}

const mapDispatchToProps = {
    successMsg: alertActions.success,
    errorMsg: alertActions.error,
    clear: alertActions.clear,
};

export default connect(null, mapDispatchToProps)(NotesForm);