import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Tooltip,
    IconButton, useTheme, Typography
} from "@mui/material";
import Spinner from "../../../spinner/Spinner";
import AlertDialog from "../../../alert/AlertDialog";
import {Delete, Edit} from "@mui/icons-material";
import React, {useState} from "react";
import {SettingsGeneralPaper} from "../../ProfileSettings";

function AffiliateNetworkIntegrations(props) {

    const theme = useTheme();
    const {deleteIntegration, integration, history, location, clear} = props;

    const [prompt, setPrompt] = useState(false);
    const [deleteIntegrationId, setDeleteIntegrationId] = useState(0);

    const edit = (rowData) => {

        clear();

        if (rowData?.networkType === "clickbank") {
            history.push({
                pathname: "/settings/integrations/clickbank/" + parseInt(rowData?.id),
                state: {background: location}
            });
        }
        if (rowData?.networkType === "sedo") {
            history.push({
                pathname: "/settings/integrations/sedo/" + parseInt(rowData?.id),
                state: {background: location}
            });
        }
        if (rowData?.networkType === "system1") {
            history.push({
                pathname: "/settings/integrations/system1/" + parseInt(rowData?.id),
                state: {background: location}
            });
        }
        if (rowData?.networkType === "bodis") {
            history.push({
                pathname: "/settings/integrations/bodis/" + parseInt(rowData?.id),
                state: {background: location}
            });
        }
        if (rowData?.networkType === "tonic") {
            history.push({
                pathname: "/settings/integrations/tonic/" + parseInt(rowData?.id),
                state: {background: location}
            });
        }
        if (rowData?.networkType === "ads-com") {
            history.push({
                pathname: "/settings/integrations/ads-com/" + parseInt(rowData?.id),
                state: {background: location}
            });
        }
        if (rowData?.networkType === "domain-active") {
            history.push({
                pathname: "/settings/integrations/domain-active/" + parseInt(rowData?.id),
                state: {background: location}
            });
        }
    };

    const addIntegration = () => {
        props.history.push({
            pathname: "/settings/integrations/add-affiliate-network",
            state: {background: props.location}
        });
    };

    const handleDeletePrompt = (id) => {
        setDeleteIntegrationId(id);
        setPrompt(true);
    };

    const handleDeleteIntegration = (prompt) => {
        if (!prompt) {
            setPrompt(false);
            return false;
        } else {
            deleteIntegration(deleteIntegrationId);
        }
    };


    return (
        <Box sx={{
            width: "100%",
            padding: "24px 30px 0 30px",
            maxWidth: "1280px",
            minWidth: "300px",
            margin: "0 auto",
            [theme.breakpoints.down(600)]: {
                padding: 0,
                marginTop: "16px"
            },
        }}>
            <SettingsGeneralPaper elevation={0}>
                <Box display="flex" flexDirection="row">

                    {integration?.loading?.affiliateNetworkIntegrations && <Spinner overComponentBox={true}/>}

                    <Box flexGrow={1}>
                        <Typography variant="h3" sx={{
                            textAlign: "left",
                            fontSize: "16px",
                            fontWeight: "bold",
                            display: "block",
                            paddingTop: "16px",
                            paddingBottom: "24px"
                        }}>
                            Revenue Sync - Affiliate Networks & Search Feed Arbitrage AFD
                        </Typography>
                    </Box>
                    <Box pt={1}>
                        <Button type="button" onClick={addIntegration} color="primary" variant="contained">
                            Add
                        </Button>
                    </Box>
                </Box>

                {prompt && <AlertDialog question="Delete confirmation"
                                        text={"Are you sure you want to delete current integration?"}
                                        confirm={handleDeleteIntegration}/>
                }

                <TableContainer sx={{maxHeight: "400px"}}>
                    <Table stickyHeader size="small" aria-label="affiliate-network-integrations">
                        <TableHead sx={{whiteSpace: "nowrap"}}>
                            <TableRow hover>
                                <TableCell>Network Name</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{whiteSpace: "nowrap"}}>
                            {integration?.affiliateNetworkIntegrations?.map((row) => (
                                <TableRow key={row?.id}>
                                    <TableCell sx={{width: "100%"}}>{row?.networkName}</TableCell>
                                    <TableCell>
                                        <Box display="flex" flexWrap="nowrap" alignItems="center"
                                             justifyContent="right">
                                            <Box mr={1}>
                                                <Tooltip title="Edit">
                                                <span>
                                                    <IconButton
                                                        size="small"
                                                        color="inherit"
                                                        onClick={() => edit(row)}
                                                        aria-label="edit"
                                                    >
                                                        <Edit fontSize="small" color="inherit"/>
                                                    </IconButton>
                                                </span>
                                                </Tooltip>
                                            </Box>
                                            <Box>
                                                <Tooltip title="Delete">
                                                <span>
                                                    <IconButton
                                                        size="small"
                                                        color="inherit"
                                                        onClick={() => handleDeletePrompt(row?.id)}
                                                        aria-label="delete"
                                                    >
                                                        <Delete fontSize="small" color="error"/>
                                                    </IconButton>
                                                </span>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </SettingsGeneralPaper>
        </Box>
    );

}

export default AffiliateNetworkIntegrations;