import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {connect} from "react-redux";
import {trafficSourceActions} from "../../store/actions/trafficSourceAction";
import {TrafficSourceForm} from "./TrafficSourceForm";
import {trafficSourceServices} from "../../service/trafficSourceService";
import {Dialog, useMediaQuery, useTheme} from "@mui/material";
import {customEventServices} from "../../service/customEventService";

function EditTrafficSource(props) {

    const [open, setOpen] = useState(true);
    const [formData, setFormData] = useState(null);
    const [trafficSourceData, setTrafficSourceData] = useState(null);
    const [requestError, setRequestError] = useState("");
    const [loading, setLoading] = useState(false);
    const [formSuccessfullySubmitted, setFormSuccessfullySubmitted] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const {register, handleSubmit, setValue, watch, control, getValues, formState: {errors}} = useForm();

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        setOpen(false);
        props.history.push({pathname: "/traffic-sources", state: {from: props.location}});
    }, [props]);

    const onSubmit = (data) => {

        // Add traffic source integration
        if (data?.integrationId?.value) {
            data.integrationId = data?.integrationId?.value;
        }

        // Add workspace id
        data.workspaceId = data?.workspace?.value;
        data.tags = data?.tags ? data?.tags : []

        // modify postbackPercentage int to string
        data.postbackPercentage = data?.postbackPercentage ? data?.postbackPercentage.toString() : ""

        // Map selectedCustomConversions as comma-separated list.
        if (data?.selectedCustomConversions !== "" && data?.selectedCustomConversions !== undefined) {
            let selectedCustomConversion = ""
            // eslint-disable-next-line array-callback-return
            data?.selectedCustomConversions?.map((item) => {
                if (selectedCustomConversion === "") {
                    selectedCustomConversion = item?.id;
                } else {
                    selectedCustomConversion += ", " + item?.id;
                }
            });
            data.selectedCustomConversions = selectedCustomConversion.toString();
        }

        // Reset postback values if usePostback is disabled.
        if (!data?.usePostback) {
            data.postbackUrl = ""
            data.disableCustomConversionsPostback = false
            data.postbackPercentage = "100"
            data.allowPostbackForSelectedCustomConversions = false
            data.selectedCustomConversions = ""
        }

        // Reset data for selectedCustomConversions
        if (!data.allowPostbackForSelectedCustomConversions) {
            data.selectedCustomConversions = ""
        }

        setFormData(data);
    };

    useEffect(() => {
        async function findById(id): void {
            try {
                setRequestError("");
                setLoading(true);
                const response = await trafficSourceServices.find(id);
                if (response.hasOwnProperty("id") && response.id > 0) {

                    // Find selected custom conversions
                    let selectedEvents = response?.selectedCustomConversions?.match(/\d+/g)?.map(Number);
                    if (selectedEvents?.length > 0) {
                        const allEvents = await customEventServices.fetch({});
                        let selectedCustomConversions = [];
                        // eslint-disable-next-line array-callback-return
                        allEvents?.filter(item => {
                            if (selectedEvents.includes(item?.id)) {
                                selectedCustomConversions.push({id: item?.id, name: item?.name});
                            }
                        });
                        response.selectedCustomConversions = selectedCustomConversions;
                    }

                    // Set response
                    setTrafficSourceData(response);
                }
            } catch (error) {
                if (error.hasOwnProperty("response") && error.response.hasOwnProperty("data") && error.response.data.hasOwnProperty("error")) {
                    setRequestError(error.response.data.error);
                }
            } finally {
                setLoading(false);
            }
        }

        if (props.match && props.match.params && props.match.params.hasOwnProperty("id") && trafficSourceData === null) {
            findById(props.match.params.id);
        }

    }, [trafficSourceData, props]);

    useEffect(() => {
        if (trafficSourceData !== null) {
            setTimeout(async () => {
                for (const [key, value] of Object.entries(trafficSourceData)) {
                    if (key !== "integrationId" && key !== "affiliateNetworkIntegrationId") {
                        setValue(key, value);
                    }
                }
            });
        }
    }, [trafficSourceData, setValue]);

    useEffect(() => {
        let id = 0;
        if (props.hasOwnProperty("match") && props.match.hasOwnProperty("params") && props.match.params.hasOwnProperty("id")) {
            id = props.match.params.id;
        }

        async function postData(): void {
            try {
                setRequestError("");
                setLoading(true);
                const response = await trafficSourceServices.update(id, formData);
                if (response.hasOwnProperty("id") && response.id > 0) {
                    props.rerender(true);
                    setFormSuccessfullySubmitted(true);
                }
            } catch (error) {
                if (error.hasOwnProperty("response") && error.response.hasOwnProperty("data") && error.response.data.hasOwnProperty("error")) {
                    setRequestError(error.response.data.error);
                }
            } finally {
                setLoading(false);
            }
        }

        if (formData !== null) {
            postData();
        }

    }, [formData, props]);

    useEffect(() => {
        if (formSuccessfullySubmitted) {
            handleClose();
        }
    }, [formSuccessfullySubmitted, handleClose]);

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                maxWidth={"md"}
                aria-labelledby="form-dialog-title"
        >
            <TrafficSourceForm
                title="Update traffic source"
                register={register}
                handleClose={handleClose}
                requestError={requestError}
                errors={errors}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                loading={loading}
                setValue={setValue}
                watch={watch}
                trafficSourceData={trafficSourceData}
                control={control}
                setRequestError={setRequestError}
                getValues={getValues}
            />
        </Dialog>
    );
}

const mapDispatchToProps = {
    rerender: trafficSourceActions.rerender
};

export default connect(null, mapDispatchToProps)(EditTrafficSource);