import React from 'react';
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {connect} from "react-redux";
import Dashboard from '../dashboard/Dashboard';
import AddOffer from "../offers/AddOffer";
import EditOffer from "../offers/EditOffer";
import DuplicateOffer from "../offers/DuplicateOffer";
import AddLanding from "../landings/AddLanding";
import DuplicateLanding from "../landings/DuplicateLanding";
import EditLanding from "../landings/EditLanding";
import AddAffiliateNetwork from "../affiliate-networks/AddAffiliateNetwork";
import EditAffiliateNetwork from "../affiliate-networks/EditAffiliateNetwork";
import DuplicateAffiliateNetwork from "../affiliate-networks/DuplicateAffiliateNetwork";
import AddTrafficSource from "../traffic-sources/AddTrafficSource";
import DuplicateTrafficSource from "../traffic-sources/DuplicateTrafficSource";
import EditTrafficSource from "../traffic-sources/EditTrafficSource";
import AddCampaign from "../campaigns/AddCampaign";
import {CampaignLinks} from "../campaigns/CampaignLinks";
import EditCampaign from "../campaigns/EditCampaign";
import DuplicateCampaign from "../campaigns/DuplicateCampaign";
import {authActions} from "../../store/actions/authAction";
import Logout from "../auth/Logout";
import General from "../settings/General";
import {Subscription} from "../settings/subscription/Subscription";
import Domains from "../settings/domains/Domains";
import ConfirmInvitation from "../auth/ConfirmInvitation";
import PageNotFound from "../404/PageNotFound";
import CampaignCost from "../campaigns/CampaignCost";
import Login from "../auth/Login";
import AccountSetup from "../auth/AccountSetup";
import RetryInvoice from "../auth/RetryInvoice";
import AddPaymentMethod from "../auth/AddPaymentMethod";
import VerifyGoogleLogin from "../auth/VerifyGoogleLogin";
import VerifyEmailLogin from "../auth/VerifyEmailLogin";
import Bot from "../settings/bot/Bot";
import AffiliateNetworksGrid from "../affiliate-networks/AffiliateNetworksGrid";
import TrafficSourcesGrid from "../traffic-sources/TrafficSourcesGrid";
import LandingsGrid from "../landings/LandingsGrid";
import OffersGrid from "../offers/OffersGrid";
import CampaignsView from "../campaigns/CampaignsView";
import Integrations from "../settings/integrations/Integrations";
import AddIntegration from "../settings/integrations/AddIntegration";
import FacebookAdAccountIntegration from "../settings/integrations/FacebookAdAccountIntegration";
import FacebookCallback from "../settings/integrations/FacebookCallback";
import PushgroundIntegration from "../settings/integrations/PushgroundIntegration";
import FlowsGrid from "../flows/FlowsGrid";
import AddFlow from "../flows/AddFlow";
import EditFlow from "../flows/EditFlow";
import DuplicateFlow from "../flows/DuplicateFlow";
import Referral from "../settings/referral/Referral";
import PropellerAdsIntegration from "../settings/integrations/PropellerAdsIntegration";
import ApiAccess from "../settings/api/ApiAccess";
import CustomEvents from "../settings/custom-events/CustomEvents";
import ClickbankIntegration from "../settings/integrations/affiliate-network/ClickbankIntegration";
import ChooseAffiliateNetworkIntegration
    from "../settings/integrations/affiliate-network/ChooseAffiliateNetworkIntegration";
import ExoClickIntegration from "../settings/integrations/ExoClickIntegration";
import MondiadIntegration from "../settings/integrations/MondiadIntegration";
import SedoIntegration from "../settings/integrations/affiliate-network/SedoIntegration";
import Team from "../team/Team";
import AddMember from "../team/AddMember";
import AddWorkspace from "../workspace/AddWorkspace";
import AssignUsers from "../workspace/AssignUsers";
import GoogleCallback from "../settings/integrations/GoogleCallback";
import GoogleAdAccountIntegration from "../settings/integrations/GoogleAdAccountIntegration";
import System1Integration from "../settings/integrations/affiliate-network/System1Integration";
import MicrosoftCallback from "../settings/integrations/MicrosoftCallback";
import MicrosoftAdAccountIntegration from "../settings/integrations/MicrosoftAdAccountIntegration";
import BodisIntegration from "../settings/integrations/affiliate-network/BodisIntegration";
import TonicIntegration from "../settings/integrations/affiliate-network/TonicIntegration";
import TaboolaIntegration from "../settings/integrations/TaboolaIntegration";
import MgidIntegration from "../settings/integrations/MgidIntegration";
import OutbrainIntegration from "../settings/integrations/OutbrainIntegration";
import TikTokCallback from "../settings/integrations/TikTokCallback";
import TikTokAdAccountIntegration from "../settings/integrations/TikTokAdAccountIntegration";
import LandingProtection from "../landing-protection/LandingProtection";
import RsocSystem1Integration from "../settings/integrations/rsoc/RsocSystem1Integration";
import ChooseRsocIntegration from "../settings/integrations/rsoc/ChooseRsocIntegration";
import LogConversionsTable from "../logs/LogConversionsTable";
import LogClicksTable from "../logs/LogClicksTable";
import LogClicksAddConversion from "../logs/LogClicksAddConversion";
import LogConversionsOverrideConversion from "../logs/LogConversionsOverrideConversion";
import LogPostbacksTable from "../logs/LogPostbacksTable";
import AdsComIntegration from "../settings/integrations/affiliate-network/AdsComIntegration";
import DomainActiveIntegration from "../settings/integrations/affiliate-network/DomainActiveIntegration";
import RsocInuvoIntegration from "../settings/integrations/rsoc/RsocInuvoIntegration";
import Websites from "../websites";
import DomainsGetCNAMERecords from "../websites/DomainsGetCNAMERecords";
import DomainsAdd from "../websites/DomainsAdd";
import AddBucket from "../websites/buckets/AddBucket";
import UploadFilesToS3Bucket from "../websites/buckets/UploadFilesToS3Bucket";
import DeployWebsite from "../websites/buckets/DeployWebsite";
import ChooseServerIntegration from "../settings/integrations/server/ChooseServerIntegration";
import ServerAwsIntegration from "../settings/integrations/server/ServerAwsIntegration";
import DomainCloudflareIntegration from "../settings/integrations/server/DomainCloudflareIntegration";
import DomainNamesiloIntegration from "../settings/integrations/server/DomainNamesiloIntegration";
import RsocAskMediaGroupIntegration from "../settings/integrations/rsoc/RsocAskMediaGroupIntegration";
import RevContentIntegration from "../settings/integrations/RevContentIntegration";
import RsocAdsComIntegration from "../settings/integrations/rsoc/RsocAdsComIntegration";
import RsocPredictoIntegration from "../settings/integrations/rsoc/RsocPredictoIntegration";
import RsocSedoIntegration from "../settings/integrations/rsoc/RsocSedoIntegration";

const subscriptionStatuses = ["", "incomplete", "incomplete_expired", "canceled"];

const Routes = (props) => {
    const {auth} = props;
    let location = useLocation();
    let background = location.state && location.state.background;

    return (
        <div>
            <Switch location={background || location}>
                <Route exact path="/account-setup" auth={auth}
                       render={(props) => {
                           if (!auth.isAuthenticated) {
                               return <Redirect to="/"/>;
                           }

                           if (auth.isAuthenticated && auth.subscription.loaded) {
                               return subscriptionStatuses.includes(auth.subscription.status) ?
                                   <AccountSetup {...props} auth={auth}/> : <Redirect to="/"/>;
                           }
                       }}
                />
                <Route exact path="/retry-invoice" auth={auth}
                       render={(props) => {
                           if (!auth.isAuthenticated) {
                               return <Redirect to="/"/>;
                           }

                           if (auth.isAuthenticated && auth.subscription.loaded) {
                               return auth.subscription.status === "past_due"
                                   ? <RetryInvoice {...props} auth={auth}/>
                                   : <Redirect to="/"/>;
                           }
                       }}
                />
                <Route exact path="/add-payment-method" auth={auth}
                       render={(props) => {
                           if (!auth.isAuthenticated) {
                               return <Redirect to="/"/>;
                           }

                           if (auth.isAuthenticated && auth.subscription.loaded) {
                               return (auth.subscription.status === "active" && auth.subscription.usedEvents >= 250000 && auth.subscription.plan === "Free" && !auth.subscription.hasPaymentMethod)
                                   ? <AddPaymentMethod {...props} auth={auth}/>
                                   : <Redirect to="/"/>;
                           }
                       }}
                />
                <PrivateRoute exact path="/" component={Dashboard} auth={auth}/>
                <PrivateRoute path="/dashboard" component={Dashboard} auth={auth}/>
                <PrivateRoute path="/campaigns" component={CampaignsView} auth={auth}/>
                <PrivateRoute path="/offers" component={OffersGrid} auth={auth}/>
                <PrivateRoute path="/landings" component={LandingsGrid} auth={auth}/>
                <PrivateRoute path="/flows" component={FlowsGrid} auth={auth}/>
                <PrivateRoute path="/traffic-sources" component={TrafficSourcesGrid} auth={auth}/>
                <PrivateRoute exact path="/affiliate-networks" component={AffiliateNetworksGrid} auth={auth}/>
                <PrivateRoute path="/log/clicks" component={LogClicksTable} auth={auth}/>
                <PrivateRoute path="/log/conversions" component={LogConversionsTable} auth={auth}/>
                <PrivateRoute path="/log/postbacks" component={LogPostbacksTable} auth={auth}/>
                <PrivateRoute path="/settings/general" component={General} auth={auth}/>
                <PrivateRoute path="/settings/bot-detection" component={Bot} auth={auth}/>
                <PrivateRoute path="/settings/domains" component={Domains} auth={auth}/>
                <PrivateRoute path="/settings/subscription" component={Subscription} auth={auth}/>
                <PrivateRoute path="/settings/team/members" component={Team} auth={auth}/>
                <PrivateRoute path="/settings/team/workspaces" component={Team} auth={auth}/>
                <PrivateRoute path="/settings/integrations/tiktok-callback" component={TikTokCallback} auth={auth}/>
                <PrivateRoute path="/settings/integrations/facebook-callback" component={FacebookCallback} auth={auth}/>
                <PrivateRoute path="/settings/integrations/google-callback" component={GoogleCallback} auth={auth}/>
                <PrivateRoute path="/settings/integrations/microsoft-callback" component={MicrosoftCallback} auth={auth}/>
                <PrivateRoute path="/settings/integrations" component={Integrations} auth={auth}/>
                <PrivateRoute path="/settings/referral" component={Referral} auth={auth}/>
                <PrivateRoute path="/settings/api-access" component={ApiAccess} auth={auth}/>
                <PrivateRoute path="/settings/custom-events" component={CustomEvents} auth={auth}/>
                <PrivateRoute path="/settings/landing-protection/php" component={LandingProtection} auth={auth}/>
                <PrivateRoute path="/settings/landing-protection/javascript" component={LandingProtection} auth={auth}/>
                <PrivateRoute path="/websites/domains" component={Websites} auth={auth}/>
                <PrivateRoute path="/websites/s3-buckets" component={Websites} auth={auth}/>
                <PrivateRoute path="/websites/deployments" component={Websites} auth={auth}/>
                <Route exact path="/logout" component={Logout}/>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/verify" component={VerifyEmailLogin}/>
                <Route exact path="/auth/google/verify" component={VerifyGoogleLogin}/>
                <Route exact path="/invitation" component={ConfirmInvitation}/>
                <Route component={PageNotFound}/>
            </Switch>
            {/* Show the modal when a background page is set */}
            {background && <PrivateRoute exact path="/campaigns/new" component={AddCampaign} auth={auth}/>}
            {background && <PrivateRoute exact path="/campaigns/links/:id" component={CampaignLinks} auth={auth}/>}
            {background && <PrivateRoute exact path="/campaigns/cost/:id" component={CampaignCost} auth={auth}/>}
            {background && <PrivateRoute exact path="/campaigns/edit/:id" component={EditCampaign} auth={auth}/>}
            {background && <PrivateRoute exact path="/campaigns/duplicate/:id" component={DuplicateCampaign} auth={auth}/>}
            {background && <PrivateRoute exact path="/offers/new" component={AddOffer} auth={auth}/>}
            {background && <PrivateRoute exact path="/offers/edit/:id" component={EditOffer} auth={auth}/>}
            {background && <PrivateRoute exact path="/offers/duplicate/:id" component={DuplicateOffer} auth={auth}/>}
            {background && <PrivateRoute exact path="/flows/new" component={AddFlow} auth={auth}/>}
            {background && <PrivateRoute exact path="/flows/edit/:id" component={EditFlow} auth={auth}/>}
            {background && <PrivateRoute exact path="/flows/duplicate/:id" component={DuplicateFlow} auth={auth}/>}
            {background && <PrivateRoute exact path="/landings/new" component={AddLanding} auth={auth}/>}
            {background && <PrivateRoute exact path="/landings/edit/:id" component={EditLanding} auth={auth}/>}
            {background && <PrivateRoute exact path="/landings/duplicate/:id" component={DuplicateLanding} auth={auth}/>}
            {background && <PrivateRoute exact path="/affiliate-networks/new" component={AddAffiliateNetwork} auth={auth}/>}
            {background && <PrivateRoute exact path="/affiliate-networks/edit/:id" component={EditAffiliateNetwork} auth={auth}/>}
            {background && <PrivateRoute exact path="/affiliate-networks/duplicate/:id" component={DuplicateAffiliateNetwork} auth={auth}/>}
            {background && <PrivateRoute exact path="/traffic-sources/new" component={AddTrafficSource} auth={auth}/>}
            {background && <PrivateRoute exact path="/traffic-sources/edit/:id" component={EditTrafficSource} auth={auth}/>}
            {background && <PrivateRoute exact path="/traffic-sources/duplicate/:id" component={DuplicateTrafficSource} auth={auth}/>}
            {background && <PrivateRoute exact path="/log/clicks/add-conversion/:id" component={LogClicksAddConversion} auth={auth}/>}
            {background && <PrivateRoute exact path="/log/conversions/override-conversion/:id" component={LogConversionsOverrideConversion} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/add" component={AddIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/add-affiliate-network" component={ChooseAffiliateNetworkIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/add-rsoc" component={ChooseRsocIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/add-server" component={ChooseServerIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/clickbank" component={ClickbankIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/clickbank/:id" component={ClickbankIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/sedo/" component={SedoIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/sedo/:id" component={SedoIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/system1/" component={System1Integration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/system1/:id" component={System1Integration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/rsoc-system1/" component={RsocSystem1Integration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/rsoc-system1/:id" component={RsocSystem1Integration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/rsoc-inuvo/" component={RsocInuvoIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/rsoc-inuvo/:id" component={RsocInuvoIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/rsoc-amg/" component={RsocAskMediaGroupIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/rsoc-amg/:id" component={RsocAskMediaGroupIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/rsoc-ads-com/" component={RsocAdsComIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/rsoc-ads-com/:id" component={RsocAdsComIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/rsoc-predicto/" component={RsocPredictoIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/rsoc-predicto/:id" component={RsocPredictoIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/rsoc-sedo/" component={RsocSedoIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/rsoc-sedo/:id" component={RsocSedoIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/bodis/" component={BodisIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/bodis/:id" component={BodisIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/domain-active/" component={DomainActiveIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/domain-active/:id" component={DomainActiveIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/ads-com/" component={AdsComIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/ads-com/:id" component={AdsComIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/tonic/" component={TonicIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/tonic/:id" component={TonicIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/tiktok/:id" component={TikTokAdAccountIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/facebook/:id" component={FacebookAdAccountIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/google/:id" component={GoogleAdAccountIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/microsoft/:id" component={MicrosoftAdAccountIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/pushground/:id" component={PushgroundIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/propeller-ads/:id" component={PropellerAdsIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/exoclick/:id" component={ExoClickIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/mondiad/:id" component={MondiadIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/taboola/:id" component={TaboolaIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/mgid/:id" component={MgidIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/outbrain/:id" component={OutbrainIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/revcontent/:id" component={RevContentIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/server-aws/" component={ServerAwsIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/server-aws/:id" component={ServerAwsIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/server-cloudflare/" component={DomainCloudflareIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/server-cloudflare/:id" component={DomainCloudflareIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/server-namesilo/" component={DomainNamesiloIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/integrations/server-namesilo/:id" component={DomainNamesiloIntegration} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/team/members/invite" component={AddMember} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/team/workspaces/add" component={AddWorkspace} auth={auth}/>}
            {background && <PrivateRoute exact path="/settings/team/workspaces/:id/assign" component={AssignUsers} auth={auth}/>}
            {background && <PrivateRoute exact path="/websites/domains/:id" component={DomainsGetCNAMERecords} auth={auth}/>}
            {background && <PrivateRoute exact path="/websites/domains/add" component={DomainsAdd} auth={auth}/>}
            {background && <PrivateRoute exact path="/websites/s3-buckets/create" component={AddBucket} auth={auth}/>}
            {background && <PrivateRoute exact path="/websites/s3-buckets/upload/:id" component={UploadFilesToS3Bucket} auth={auth}/>}
            {background && <PrivateRoute exact path="/websites/s3-buckets/deploy/:id" component={DeployWebsite} auth={auth}/>}
        </div>
    );
};

const PrivateRoute = ({auth, component: Component, ...rest}) => {
    return (
        <Route {...rest} render={props => {
            if (auth.isAuthenticated && auth.subscription.loaded) {
                if (subscriptionStatuses.includes(auth.subscription.status)) {
                    return subscriptionStatuses.includes(auth.subscription.status) ?
                        <Redirect to="/account-setup"/> : <Component {...props}/>
                } else if (auth.subscription.status === "past_due") {
                    return auth.subscription.status === "past_due" ? <Redirect to="/retry-invoice"/> :
                        <Component {...props}/>
                } else if (auth.subscription.status === "active" && auth.subscription.usedEvents >= 250000 && auth.subscription.plan === "Free" && !auth.subscription.hasPaymentMethod) {
                    return <Redirect to="/add-payment-method"/>
                } else {
                    return auth.isAuthenticated ? <Component {...props}/> :
                        <Redirect to={{pathname: '/login', state: {from: props.location}}}/>;
                }
            }

            if (!auth.isAuthenticated) {
                return <Redirect to={{pathname: '/login', state: {from: props.location}}}/>;
            }
        }}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = {
    updateAuthenticationStatus: authActions.updateAuthenticationStatus,
    logout: authActions.logout
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
