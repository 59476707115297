import {TextField, Chip, FormHelperText, InputAdornment, Box} from "@mui/material";
import React, {useState} from "react";

export default function PostbackURLWithParams(params) {
    const {label, name, register, errors, watch, setValue} = params;
    const [cursor, setCursor] = useState("");

    const handleChipClick = (item) => {
        let e = cursor;
        if (cursor === "" || cursor === undefined) {
            return false;
        }

        let s = {start: 0, end: 0};
        if (typeof e.target.selectionStart == "number" && typeof e.target.selectionEnd == "number") {
            s.start = e.target.selectionStart;
            s.end = e.target.selectionEnd;
        }

        if (s.start !== s.end) {
            // Replace selected text range.
            let before = e.target.value.slice(0, s.start);
            let after = e.target.value.slice(s.end);
            e.target.value = before + item + after;
            e.target.selectionEnd = before?.length + item?.length;
        } else {
            // Set new item where cursor is.
            let cursorPosition = e.target.selectionStart;
            let textBeforeCursorPosition = e.target.value.substring(0, cursorPosition);
            let textAfterCursorPosition = e.target.value.substring(cursorPosition, e.target.value.length);
            e.target.value = textBeforeCursorPosition + item + textAfterCursorPosition;
            e.target.selectionEnd = textBeforeCursorPosition?.length + item?.length;
        }

        setValue(name, e.target.value);
    };

    const urlChip = (placeholder) => {
        let url = watch(name);
        return (
            <Chip label={placeholder} variant="filled" color="primary" size="small"
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => handleChipClick(placeholder)}
                  disabled={!!url?.includes(placeholder)}
            />
        );
    };

    const handleOnClick = (e) => {
        setCursor(e);
    };

    return (
        <Box pt={1} pb={1}>
            <TextField
                error={!!errors?.postbackUrl}
                variant="outlined"
                margin="dense"
                size="small"
                {...register(name, {
                    required: true,
                    pattern: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,10}(:[0-9]{1,5})?(\/.*)?$/
                })}
                name={name}
                label={label}
                placeholder="e.g. https://example.com/postback?externalid={externalId}"
                type="text"
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">GET</InputAdornment>
                }}
                onClick={handleOnClick}
                onChange={handleOnClick}
            />

            <FormHelperText sx={{color: "error.main", marginBottom: 1, paddingTop: 1}}>
                {errors?.postbackUrl?.message}
            </FormHelperText>

            {urlChip("{externalId}")}
            {urlChip("{payout}")}
            {urlChip("{payoutCurrency}")}
            {urlChip("{cps}")}
            {urlChip("{conversionEventName}")}
            {urlChip("{campaignId}")}
            {urlChip("{campaignName}")}
            {urlChip("{trafficSourceId}")}
            {urlChip("{trafficSourceName}")}
            {urlChip("{deviceType}")}
            {urlChip("{deviceVendor}")}
            {urlChip("{deviceModel}")}
            {urlChip("{browser}")}
            {urlChip("{browserVersion}")}
            {urlChip("{os}")}
            {urlChip("{osVersion}")}
            {urlChip("{isp}")}
            {urlChip("{ip}")}
            {urlChip("{country}")}
            {urlChip("{state}")}
            {urlChip("{city}")}
            {urlChip("{lang}")}
            {urlChip("{trackingDomain}")}
            {urlChip("{userAgent}")}
            {urlChip("{referrerDomain}")}
            {urlChip("{custom1}")}
            {urlChip("{custom2}")}
            {urlChip("{custom3}")}
            {urlChip("{custom4}")}
            {urlChip("{custom5}")}
            {urlChip("{custom6}")}
            {urlChip("{custom7}")}
            {urlChip("{custom8}")}
            {urlChip("{custom9}")}
            {urlChip("{custom10}")}
        </Box>
    );
}