import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Dialog, useMediaQuery, useTheme} from "@mui/material";
import {TrafficSourceTemplates} from "./TrafficSourceTemplates";
import {TrafficSourceForm} from "./TrafficSourceForm";
import {trafficSourceServices} from "../../service/trafficSourceService";

function TrafficSourcePopUp({onClose, open}) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [loading, setLoading] = useState(false);
    const [requestError, setRequestError] = useState("");
    const [step, setStep] = useState("templates");
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const {register, handleSubmit, setValue, setError, watch, control, getValues, formState: {errors}} = useForm();

    const handleSelectedTemplate = (data) => {
        setSelectedTemplate(data);
        setStep("custom");
    };

    useEffect(() => {
        if (selectedTemplate !== undefined && selectedTemplate !== null) {
            setTimeout(() => {
                setValue("name", selectedTemplate?.name);
                setValue("usePostback", selectedTemplate?.usePostback);
                setValue("postbackUrl", selectedTemplate?.postbackUrl);
                setValue("postbackPercentage", selectedTemplate?.postbackPercentage);
                setValue("externalClickIdParam", selectedTemplate?.externalClickIdParam);
                setValue("externalClickIdPlaceholder", selectedTemplate?.externalClickIdPlaceholder);
                setValue("costParam", selectedTemplate?.costParam);
                setValue("costPlaceholder", selectedTemplate?.costPlaceholder);
                setValue("custom1Param", selectedTemplate?.custom1Param);
                setValue("custom1Placeholder", selectedTemplate?.custom1Placeholder);
                setValue("custom1Name", selectedTemplate?.custom1Name);
                setValue("custom2Param", selectedTemplate?.custom2Param);
                setValue("custom2Placeholder", selectedTemplate?.custom2Placeholder);
                setValue("custom2Name", selectedTemplate?.custom2Name);
                setValue("custom3Param", selectedTemplate?.custom3Param);
                setValue("custom3Placeholder", selectedTemplate?.custom3Placeholder);
                setValue("custom3Name", selectedTemplate?.custom3Name);
                setValue("custom4Param", selectedTemplate?.custom4Param);
                setValue("custom4Placeholder", selectedTemplate?.custom4Placeholder);
                setValue("custom4Name", selectedTemplate?.custom4Name);
                setValue("custom5Param", selectedTemplate?.custom5Param);
                setValue("custom5Placeholder", selectedTemplate?.custom5Placeholder);
                setValue("custom5Name", selectedTemplate?.custom5Name);
                setValue("custom6Param", selectedTemplate?.custom6Param);
                setValue("custom6Placeholder", selectedTemplate?.custom6Placeholder);
                setValue("custom6Name", selectedTemplate?.custom6Name);
                setValue("custom7Param", selectedTemplate?.custom7Param);
                setValue("custom7Placeholder", selectedTemplate?.custom7Placeholder);
                setValue("custom7Name", selectedTemplate?.custom7Name);
                setValue("custom8Param", selectedTemplate?.custom8Param);
                setValue("custom8Placeholder", selectedTemplate?.custom8Placeholder);
                setValue("custom8Name", selectedTemplate?.custom8Name);
                setValue("custom9Param", selectedTemplate?.custom9Param);
                setValue("custom9Placeholder", selectedTemplate?.custom9Placeholder);
                setValue("custom9Name", selectedTemplate?.custom9Name);
                setValue("custom10Param", selectedTemplate?.custom10Param);
                setValue("custom10Placeholder", selectedTemplate?.custom10Placeholder);
                setValue("custom10Name", selectedTemplate?.custom10Name);
            });
        }
    }, [setValue, selectedTemplate, open]);

    const onSubmit = async (formData) => {
        if (formData.usePostback && formData?.postbackUrl.includes("REPLACE")) {
            setError("postbackUrl", {
                type: "manual",
                message: "Please replace parameter values where needed."
            });
            return false;
        }

        // Add traffic source integration
        if (formData?.integrationId?.value) {
            formData.integrationId = formData?.integrationId?.value;
        }

        // Add workspace id
        formData.workspaceId = formData?.workspace?.value;
        formData.tags = formData?.tags ? formData?.tags : [];

        // Map selectedCustomConversions as comma-separated list.
        if (formData?.selectedCustomConversions !== "" && formData?.selectedCustomConversions !== undefined) {
            let selectedCustomConversion = ""
            // eslint-disable-next-line array-callback-return
            formData?.selectedCustomConversions?.map((item) => {
                if (selectedCustomConversion === "") {
                    selectedCustomConversion = item?.id;
                } else {
                    selectedCustomConversion += ", " + item?.id;
                }
            });
            formData.selectedCustomConversions = selectedCustomConversion.toString();
        }

        setLoading(true);
        await trafficSourceServices.create(formData).then(() => {
            handleClose();
        }).catch((e) => {
            if (e?.response?.data?.error !== undefined) {
                setRequestError(e.response.data.error);
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        setRequestError("");
        setStep("templates");
        setSelectedTemplate(null);
        onClose();
    };

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                maxWidth={"md"}
                aria-labelledby="form-dialog-title"
        >
            {step === "templates" && <TrafficSourceTemplates
                handleClose={handleClose}
                handleSelectedTemplate={handleSelectedTemplate}
            />}

            {step === "custom" && <TrafficSourceForm
                title="New traffic source"
                register={register}
                handleClose={handleClose}
                requestError={requestError}
                errors={errors}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                loading={loading}
                setValue={setValue}
                watch={watch}
                trafficSourceData={selectedTemplate}
                control={control}
                setRequestError={setRequestError}
                getValues={getValues}
            />}
        </Dialog>
    );
}

export default TrafficSourcePopUp;