import {Button, DialogActions, DialogContent, DialogTitle, TextField, Alert, Box, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import {sharedTrafficSourceService} from "../../../service/sharedTrafficSourceService";
import Spinner from "../../spinner/Spinner";
import {integrationActions} from "../../../store/actions/integrationAction";
import {connect} from "react-redux";

function ChooseTrafficSourceIntegration(props) {
    const theme = useTheme();
    const [loading, setLoading] = useState(true);
    const [requestError, setRequestError] = useState("");
    const [templates, setTemplates] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");

    const integrations = ["Google Ads", "TikTok", "Facebook", "Microsoft Ads", "Taboola", "MGID", "Outbrain", "PropellerAds", "Pushground", "ExoClick", "Mondiad", "Revcontent"];

    const handleChange = event => {
        setSearchQuery(event.target.value);
    };

    const filteredTemps = !searchQuery
        ? templates
        : templates?.filter(item =>
            item?.name?.toLowerCase().includes(searchQuery.toLocaleLowerCase())
        );

    useEffect(() => {
        async function fetchData() {
            setRequestError("");
            setLoading(true);
            await sharedTrafficSourceService.read().then((response) => {
                if (response !== null && response !== undefined) {
                    setTemplates(response?.sharedTrafficSources);
                }
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    setRequestError(error.response.data.error);
                }
            }).finally(() => {
                setLoading(false);
            });
        }

        if (templates === null) {
            fetchData();
        }
    }, [templates]);

    const selectIntegration = (item) => {
        props.updateSelectedIntegration(item);
    };

    const handleCloseButton = () => {
        props.handleClose();
    };

    return (
        <>
            <DialogTitle sx={{textAlign: "center"}}>Add integration</DialogTitle>
            <DialogContent sx={{[theme.breakpoints.up(959)]: {minHeight: "calc(100vh - 208px)"}}}>
                {loading && <Spinner overComponentBox={true}/>}
                {requestError.length > 1 &&
                    <Alert sx={{marginBottom: "10px"}} variant="outlined" severity="error">
                        {requestError}
                    </Alert>
                }
                <Box display="flex" flexWrap="wrap" justifyContent="center">
                    <TextField
                        variant="outlined"
                        margin="dense"
                        name="traffic-source"
                        placeholder="Search..."
                        type="text"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={searchQuery}
                        onChange={handleChange}
                    />

                    {filteredTemps?.map((item, index) => {
                        if (integrations.includes(item?.name)) {
                            return (
                                <Box key={index} flexGrow={0}>
                                    <Button sx={{
                                        width: "180px",
                                        height: "56px",
                                        margin: "6px",
                                        backgroundColor: theme.palette.common.white,
                                        textTransform: "none",
                                        [theme.breakpoints.down(500)]: {
                                            width: "140px",
                                            height: "56px",
                                        }
                                    }} variant="contained" color="inherit"
                                            onClick={() => selectIntegration(item)}
                                    >
                                        {item?.logoUrl !== ""
                                            ? <img style={{maxWidth: "85%", maxHeight: "84%"}} src={item?.logoUrl}
                                                   alt={item?.name}/>
                                            : item?.name
                                        }
                                    </Button>
                                </Box>
                            );
                        } else {
                            return null;
                        }
                    })}
                    {filteredTemps?.length < 1 && <p style={{textAlign: "center"}}>No records found.</p>}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseButton} color="inherit" variant="outlined">
                    Cancel
                </Button>
            </DialogActions>
        </>
    );
}

const mapDispatchToProps = {
    updateSelectedIntegration: integrationActions.updateSelectedIntegration
};

export default connect(null, mapDispatchToProps)(ChooseTrafficSourceIntegration);