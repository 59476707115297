import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {connect} from "react-redux";
import {affiliateNetworkActions} from "../../store/actions/affiliateNetworkAction";
import {affNetworkServices} from "../../service/affiliateNetworkService";
import {AffiliateNetworkTemplates} from "./AffiliateNetworkTemplates";
import {AffiliateNetworkForm} from "./AffiliateNetworkForm";
import {postbackURL} from "../../helpers/config";
import {GetPostbackLink} from "../../helpers/common";
import {Dialog, useMediaQuery, useTheme} from "@mui/material";

function AddAffiliateNetwork(props) {

    const [open, setOpen] = useState(true);
    const [step, setStep] = useState("templates");
    const [formData, setFormData] = useState(null);
    const [requestError, setRequestError] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [postbackLink, setPostbackLink] = useState(postbackURL);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const {register, handleSubmit, setError, setValue, watch, control, getValues, formState: {errors}} = useForm();

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        setOpen(false);
        props.history.push({pathname: "/affiliate-networks", state: {from: props.location}});
    }, [props.history, props.location]);

    useEffect(() => {
        async function postData() {
            setRequestError("");
            setLoading(true);
            await affNetworkServices.create(formData).then((response) => {
                if (response?.id > 0) {
                    props.rerender();
                    setLoading(false);
                    handleClose();
                } else {
                    setLoading(false);
                }
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    setRequestError(error.response.data.error);
                }
                setLoading(false);
            });
        }

        if (formData !== null) {
            postData();
        }

    }, [handleClose, formData, props]);

    const handleSelectedTemplate = (data) => {
        setSelectedTemplate(data);
        setStep("custom");
    };

    useEffect(() => {
        if (selectedTemplate !== undefined && selectedTemplate !== null) {
            setValue("name", selectedTemplate?.name);
            setValue("allowDoubleConversions", selectedTemplate?.allowDoubleConversions ? selectedTemplate?.allowDoubleConversions : false);
            setValue("useConversionVisitTime", selectedTemplate?.useConversionVisitTime ? selectedTemplate?.useConversionVisitTime : false);
            setValue("usePostbackStatuses", selectedTemplate?.usePostbackStatuses);
            setValue("postbackOnlyWhiteIps", selectedTemplate?.postbackOnlyWhiteIps);

            setTimeout(() => {
                setPostbackLink(
                    GetPostbackLink(
                        selectedTemplate?.clickIdPlaceholder,
                        selectedTemplate?.payoutPlaceholder,
                        selectedTemplate?.transactionIdPlaceholder,
                        selectedTemplate?.statusPlaceholder
                    )
                );
                setValue("postbackStatusApproved", selectedTemplate?.postbackStatusApproved);
                setValue("postbackStatusPending", selectedTemplate?.postbackStatusPending);
                setValue("postbackStatusDeclined", selectedTemplate?.postbackStatusDeclined);
                setValue("postbackStatusRefund", selectedTemplate?.postbackStatusRefund);
                setValue("postbackWhiteIps", selectedTemplate?.postbackWhiteIps);
                setValue("workspace", {value: 0, label: ""});
            });
        } else {
            setPostbackLink(GetPostbackLink());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTemplate, open]);

    const onSubmit = (data) => {

        if (data?.workspace?.value === 0) {
            setError("workspace", {
                type: "manual",
                message: "Please select workspace."
            });
            return false;
        }

        setFormData({
            workspaceId: data?.workspace?.value,
            name: data.name,
            sharedAffiliateNetworkId: selectedTemplate?.id,
            postbackOnlyWhiteIps: data.postbackOnlyWhiteIps,
            postbackWhiteIps: data.postbackWhiteIps ? data.postbackWhiteIps : "",
            allowDoubleConversions: data.allowDoubleConversions,
            useConversionVisitTime: data.useConversionVisitTime,
            usePostbackStatuses: data.usePostbackStatuses,
            postbackStatusApproved: data.postbackStatusApproved ? data.postbackStatusApproved : "",
            postbackStatusPending: data.postbackStatusPending ? data.postbackStatusPending : "",
            postbackStatusDeclined: data.postbackStatusDeclined ? data.postbackStatusDeclined : "",
            postbackStatusRefund: data.postbackStatusRefund ? data.postbackStatusRefund : "",
            usePostbackProtection: data.usePostbackProtection,
            postbackProtectionToken: data.postbackProtectionToken,
            tags: data?.tags ? data?.tags : []
        });
    };

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                maxWidth={"md"}
                aria-labelledby="form-dialog-title"
        >
            {step === "templates" && <AffiliateNetworkTemplates
                handleClose={handleClose}
                handleSelectedTemplate={handleSelectedTemplate}
            />}

            {step === "custom" && <AffiliateNetworkForm
                title="New affiliate network"
                handleClose={handleClose}
                requestError={requestError}
                onSubmit={onSubmit}
                control={control}
                register={register}
                errors={errors}
                handleSubmit={handleSubmit}
                loading={loading}
                postbackLink={postbackLink}
                watch={watch}
                formData={selectedTemplate}
                getValues={getValues}
                setValue={setValue}
            />}
        </Dialog>
    );
}

const mapDispatchToProps = {
    rerender: affiliateNetworkActions.rerender
};

export default connect(null, mapDispatchToProps)(AddAffiliateNetwork);
