import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    useMediaQuery,
    useTheme,
    Alert,
    Box
} from "@mui/material";
import React, {useEffect, useState, useCallback} from "react";
import Spinner from "../../../spinner/Spinner";
import {sharedAffiliateNetworkService} from "../../../../service/sharedAffiliateNetworkService";

function ChooseRsocIntegration(props) {

    const theme = useTheme();
    const [loading, setLoading] = useState(true);
    const [requestError, setRequestError] = useState("");
    const [templates, setTemplates] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");

    const integrations = ["System1", "Inuvo", "Ask Media Group", "Ads.com", "Predicto", "Sedo"];

    const [open, setOpen] = useState(true);

    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleChange = event => {
        setSearchQuery(event.target.value);
    };

    const selectIntegration = (item) => {
        if (item?.name === "System1") {
            props.history.push({pathname: "/settings/integrations/rsoc-system1", state: {from: props.location}});
        }
        if (item?.name === "Inuvo") {
            props.history.push({pathname: "/settings/integrations/rsoc-inuvo", state: {from: props.location}});
        }
        if (item?.name === "Ask Media Group") {
            props.history.push({pathname: "/settings/integrations/rsoc-amg", state: {from: props.location}});
        }
        if (item?.name === "Ads.com") {
            props.history.push({pathname: "/settings/integrations/rsoc-ads-com", state: {from: props.location}});
        }
        if (item?.name === "Predicto") {
            props.history.push({pathname: "/settings/integrations/rsoc-predicto", state: {from: props.location}});
        }
        if (item?.name === "Sedo") {
            props.history.push({pathname: "/settings/integrations/rsoc-sedo", state: {from: props.location}});
        }
    };

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        setOpen(false);
        props.history.push({pathname: "/settings/integrations", state: {from: props.location}});
    }, [props]);

    const filteredTemps = !searchQuery
        ? templates
        : templates?.filter(item =>
            item?.name?.toLowerCase().includes(searchQuery.toLocaleLowerCase())
        );

    useEffect(() => {
        async function fetchData() {
            setRequestError("");
            setLoading(true);
            await sharedAffiliateNetworkService.read().then((response) => {
                if (response !== null && response !== undefined) {
                    setTemplates(response?.sharedAffiliateNetworks);
                }
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    setRequestError(error.response.data.error);
                }
            }).finally(() => {
                setLoading(false);
            });
        }

        if (templates === null) {
            fetchData();
        }
    }, [templates]);

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                maxWidth={"sm"}
                aria-labelledby="choose-rsoc-integration"
        >
            <DialogTitle style={{textAlign: "center"}}>Add integration</DialogTitle>
            <DialogContent sx={{[theme.breakpoints.up(959)]: {minHeight: "calc(100vh - 208px)"}}}>
                {loading && <Spinner overComponentBox={true}/>}
                {requestError.length > 1 &&
                    <Alert sx={{marginBottom: 1}} variant="outlined" severity="error">
                        {requestError}
                    </Alert>
                }
                <Box display="flex" flexWrap="wrap" justifyContent="center">
                    <TextField
                        variant="outlined"
                        margin="dense"
                        name="rsoc"
                        placeholder="Search..."
                        size="small"
                        type="text"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={searchQuery}
                        onChange={handleChange}
                    />

                    {filteredTemps?.map((item, index) => {
                        if (integrations.includes(item?.name)) {
                            return (
                                <Box key={index} flexGrow={0}>
                                    <Button
                                        sx={{
                                            width: "180px",
                                            height: "56px",
                                            margin: "6px",
                                            backgroundColor: theme.palette.common.white,
                                            textTransform: "none",
                                            [theme.breakpoints.down(500)]: {
                                                width: 140,
                                                height: 56,
                                            }
                                        }}
                                        variant="contained"
                                        color="inherit"
                                        onClick={() => selectIntegration(item)}
                                    >
                                        {item?.logoUrl !== "" ?
                                            <img
                                                style={{maxWidth: "94%", maxHeight: "84%"}}
                                                src={item?.logoUrl}
                                                alt={item?.name}
                                            />
                                            : item?.name
                                        }
                                    </Button>
                                </Box>
                            );
                        } else {
                            return null;
                        }
                    })}
                    {filteredTemps?.length < 1 &&
                        <Box p={1} textAlign="center">No records found.</Box>
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit" variant="outlined">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ChooseRsocIntegration;