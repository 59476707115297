import {
    Alert,
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider, FormControlLabel,
    InputAdornment,
    Switch,
    TextareaAutosize,
    TextField,
    Tooltip
} from "@mui/material";
import {postbackURL} from "../../helpers/config";
import Spinner from "../spinner/Spinner";
import React from "react";
import CopyContentToClipboard from "../utils/CopyContentToClipboard";
import {RenderShopifyPostbackScript} from "../campaigns/CampaignLinks";
import {GenerateRandomToken, GetDefaultTrackingDomain} from "../../helpers/common";
import {Controller} from "react-hook-form";
import WorkspaceDropdown from "../workspace/WorkspaceDropdown";
import ReactHookTagsSelect from "../tags/ReactHookTagsSelect";
import {Info} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import useCustomStyles from "../utils/UseCustomStyles";

const customStyles = (theme) => ({
    formHeight: {
        minHeight: "300px"
    },
    textArea: {
        width: "100%",
        maxWidth: 400,
        color: theme.palette.text.primary,
        borderColor: "#2c353c",
        borderWidth: 1,
        fontSize: 16,
        outlined: "",
        padding: 10.5,
        borderRadius: 4,
        backgroundColor: theme.palette.background.input,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        "&:focus": {
            borderColor: theme.palette.primary.main,
            borderWidth: 1,
            outline: 0
        },
        "&::placeholder": {
            fontSize: 12,
            color: "rgba(195, 195, 195, 1)"
        },
    },
    toolTipHelp: theme.toolTipHelp,
    errorMsg: {
        marginBottom: 10
    },
    statusInput: {
        width: "100%",
        maxWidth: 500
    },
    postbackProtectionTextField: {
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0,
        },
    }
});

export const AffiliateNetworkForm = (props) => {
    const {
        control,
        watch,
        title,
        handleClose,
        requestError,
        register,
        errors,
        handleSubmit,
        onSubmit,
        loading,
        postbackLink,
        formData,
        getValues,
        setValue
    } = props;
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);

    const watchUsePostbackProtection = watch("usePostbackProtection");
    const watchIncludeAdditionalParameters = watch("includeAdditionalParameters");
    const watchPostbackProtectionToken = watch("postbackProtectionToken");

    const renderPostbackURL = () => {
        if (watch("name") !== undefined && watch("name").toLowerCase().includes("shopify")) {
            return (<RenderShopifyPostbackScript trackingDomain={GetDefaultTrackingDomain()}/>);
        } else if (watchUsePostbackProtection || watchIncludeAdditionalParameters) {
            let protectedPostbackLink = postbackLink;
            if (watchPostbackProtectionToken !== undefined && watchPostbackProtectionToken !== "") {
                protectedPostbackLink = postbackLink + "&protectionToken=" + watchPostbackProtectionToken
            }
            if (watchIncludeAdditionalParameters) {
                protectedPostbackLink = protectedPostbackLink + "&p1=OPTIONAL&p2=OPTIONAL&p3=OPTIONAL&p4=OPTIONAL&p5=OPTIONAL";
            }
            return (<>
                <CopyContentToClipboard
                    label={"Postback URL"}
                    copyText={postbackLink === undefined ? postbackURL : protectedPostbackLink}
                    showStartAdornment={true}
                    startAdornmentText="GET"
                />
                <Box dispaly="flex" flexDirection="row" flexWrap="nowrap">
                    <Box>
                        <Controller
                            name="includeAdditionalParameters"
                            control={control}
                            render={({field: {onChange, value, ...props}}) => (
                                renderSwitchButton(
                                    onChange,
                                    value,
                                    "Include additional parameters in the postback URL",
                                    "If you need to send additional information via the postback URL, you " +
                                    "can use the p1, p2, p3, p4, and p5 parameters to pass any information " +
                                    "from an affiliate network with a token. Just add this additional parameter " +
                                    "with an appropriate affiliate network token or value and Skro will record it."
                                )
                            )}
                            defaultValue={false}
                        />
                    </Box>
                </Box>
                <Alert severity="info" variant="filled" style={{marginTop: 8}}>
                    Server-to-server postback URLs are the most reliable method to count conversions. Copy a
                    postback URL, modify it with the tokens supported by the affiliate network, and then paste it to
                    the affiliate network side. Double-check if you have included the Skro {"{clickId}"} token with
                    the correct parameter name in your offer URL. Otherwise, the conversions wouldn’t be registered.
                </Alert>
            </>);
        } else {
            return (
                <>
                    <CopyContentToClipboard
                        label={"Postback URL"}
                        copyText={postbackLink === undefined ? postbackURL : postbackLink}
                        showStartAdornment={true}
                        startAdornmentText="GET"
                    />
                    <Box dispaly="flex" flexDirection="row" flexWrap="nowrap">
                        <Box>
                            <Controller
                                name="includeAdditionalParameters"
                                control={control}
                                render={({field: {onChange, value, ...props}}) => (
                                    renderSwitchButton(
                                        onChange,
                                        value,
                                        "Include additional parameters in the postback URL",
                                        "If you need to send additional information via the postback URL, you " +
                                        "can use the p1, p2, p3, p4, and p5 parameters to pass any information " +
                                        "from an affiliate network with a token. Just add this additional parameter " +
                                        "with an appropriate affiliate network token or value and Skro will record it."
                                    )
                                )}
                                defaultValue={false}
                            />
                        </Box>
                    </Box>
                    <Alert severity="info" variant="filled" style={{marginTop: 8}}>
                        Server-to-server postback URLs are the most reliable method to count conversions. Copy a
                        postback URL, modify it with the tokens supported by the affiliate network, and then paste it to
                        the affiliate network side. Double-check if you have included the Skro {"{clickId}"} token with
                        the correct parameter name in your offer URL. Otherwise, the conversions wouldn’t be registered.
                    </Alert>
                </>
            );
        }
    };

    const renderSwitchButton = (onChange, value, tooltipLabel, tooltipTitle) => {
        return (
            <FormControlLabel
                control={
                    <Switch
                        onChange={(e) => onChange(e.target.checked)}
                        checked={value}
                        color="primary"
                    />
                }
                label={(
                    <span>
                        <span>{tooltipLabel}</span>
                        <Tooltip disableFocusListener
                                 className={classes?.toolTipHelp}
                                 title={tooltipTitle}>
                        <Info color={"primary"} fontSize={"small"}/>
                        </Tooltip>
                    </span>
                )}
            />
        );
    };

    const handleOnClickGenerateToken = () => {
        const token = GenerateRandomToken(10);
        setValue("postbackProtectionToken", token);
    }

    return (
        <>
            <DialogTitle sx={{textAlign: "center"}}>{title}</DialogTitle>
            <DialogContent>
                {requestError.length > 1 &&
                    <Alert className={classes?.errorMsg} variant="filled" severity="error">{requestError}</Alert>}
                <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"} style={{minHeight: "300px"}}>
                    <Box display="flex" mb={1}>
                        <Box width={450}>
                            <WorkspaceDropdown
                                errors={errors} control={control} workspaceId={formData?.workspaceId}
                                setValue={setValue}
                            />
                        </Box>
                        <Box p={1}>
                            <Box sx={{paddingTop: "10px"}}>
                                <Tooltip disableFocusListener
                                         className={classes?.toolTipHelp}
                                         title="Workspace allows to make the required affiliate network accessible only by a specific user or group of users.">
                                    <Info color={"primary"} fontSize={"small"}/>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" mb={2}>
                        <Box width={450}>
                            <TextField
                                error={!!errors.name}
                                variant="outlined"
                                margin="dense"
                                size="small"
                                id="name"
                                {...register("name", {required: true, maxLength: 255, minLength: 3})}
                                name="name"
                                label="Name*"
                                placeholder="Affiliate network name"
                                type="text"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        <Box flexGrow={1} pt={1}>
                            <ReactHookTagsSelect
                                control={control}
                                inputName="tags"
                                inputLabel="Tags"
                                inputLabelShrink={true}
                                getValues={getValues}
                                classes={classes}
                            />
                        </Box>
                    </Box>

                    <Divider/>
                    <Box display="flex" width="100%" flexDirection="column" mt={2} mb={2}>
                        {renderPostbackURL()}
                    </Box>
                    <Divider/>

                    <Box dispaly="flex" flexDirection="row" flexWrap="nowrap" mt={1}>
                        <Box>
                            <Controller
                                name="allowDoubleConversions"
                                control={control}
                                render={({field: {onChange, value, ...props}}) => (
                                    renderSwitchButton(
                                        onChange, value,
                                        "Allow double conversions",
                                        "This option allows you to receive double conversions from the same click ID. For example you can use it for upsell offers."
                                    )
                                )}
                                defaultValue={false}
                            />
                        </Box>
                    </Box>

                    <Box dispaly="flex" flexDirection="row" flexWrap="nowrap" mt={1}>
                        <Box>
                            <Controller
                                name="useConversionVisitTime"
                                control={control}
                                render={({field: {onChange, value, ...props}}) => (
                                    renderSwitchButton(
                                        onChange, value,
                                        "Use conversion visit time",
                                        "Conversions will be recorded based on the visit date and time in reports. This means that even if a postback is triggered on the next day, the conversion will still be attributed to the original visit date and time. This change applies only to new conversions."
                                    )
                                )}
                                defaultValue={false}
                            />
                        </Box>
                    </Box>

                    <Box display="flex" flexDirection="column" mt={1}>
                        <Box>
                            <Controller
                                name="usePostbackProtection"
                                control={control}
                                render={({field: {onChange, value, ...props}}) => (
                                    renderSwitchButton(
                                        onChange, value,
                                        "Enable postback protection",
                                        "This option allows you to generate a secret token to prevent fake conversions. Protection token will be added to your postback URL."
                                    )
                                )}
                                defaultValue={false}
                            />
                        </Box>
                        {watchUsePostbackProtection &&
                            <Box display="flex" flexDirection="row" flexWrap="wrap">
                                <Box flexGrow={1}>
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        error={!!errors.postbackProtectionToken}
                                        {...register("postbackProtectionToken", {
                                            maxLength: 32,
                                            minLength: 1,
                                            required: true
                                        })}
                                        name="postbackProtectionToken"
                                        type="text"
                                        size="small"
                                        label="Protection token"
                                        placeholder="Enter or generate a protection token"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-adornedEnd': {
                                                    paddingRight: 0,
                                                }
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Button onClick={handleOnClickGenerateToken} color="secondary"
                                                            variant="contained">
                                                        Generate Token
                                                    </Button>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Box>
                                <Box mt={1.2}>

                                </Box>
                            </Box>
                        }
                    </Box>

                    <Box dispaly="flex" flexDirection="row" flexWrap="nowrap" mt={1}>
                        <Box>
                            <Controller
                                name="usePostbackStatuses"
                                control={control}
                                render={({field: {onChange, value, ...props}}) => (
                                    renderSwitchButton(
                                        onChange, value,
                                        "Use postback statuses",
                                        "This option allows to pass conversion statuses from your affiliate network."
                                    )
                                )}
                                defaultValue={false}
                            />
                        </Box>
                    </Box>

                    {watch("usePostbackStatuses") &&
                        <Box display="flex" flexWrap="nowrap" flexDirection="column">
                            <Box display="flex" flexDirection="row" flexWrap="nowrap" className={classes?.statusInput}
                                 mt={1}>
                                <Box flexGrow={1}>
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        error={!!errors.postbackStatusApproved}
                                        {...register("postbackStatusApproved", {maxLength: 250})}
                                        size="small"
                                        name="postbackStatusApproved"
                                        type="text"
                                        label="Approved"
                                        placeholder="comma-separated values, e.g. lead, paid, confirmed"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Box>
                                <Box p={1} mt={1}>
                                    <Tooltip disableFocusListener
                                             className={classes?.toolTipHelp}
                                             title="Conversion with this status will be marked as confirmed and payout will be added to the report.">
                                        <Info color={"primary"} fontSize={"small"}/>
                                    </Tooltip>
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="row" flexWrap="nowrap" className={classes?.statusInput}
                                 mt={1}>
                                <Box flexGrow={1}>
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        error={!!errors.postbackStatusPending}
                                        size="small"
                                        {...register("postbackStatusPending", {maxLength: 250})}
                                        name="postbackStatusPending"
                                        type="text"
                                        label="Pending"
                                        placeholder="comma-separated values, e.g. new, signup, register"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Box>
                                <Box p={1} mt={1}>
                                    <Tooltip disableFocusListener
                                             className={classes?.toolTipHelp}
                                             title="A pending conversion is classified as new. Not paid successful event on your offer. Payout will not be added.">
                                        <Info color={"primary"} fontSize={"small"}/>
                                    </Tooltip>
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="row" flexWrap="nowrap" className={classes?.statusInput}
                                 mt={1}>
                                <Box flexGrow={1}>
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        error={!!errors.postbackStatusDeclined}
                                        {...register("postbackStatusDeclined", {maxLength: 250})}
                                        name="postbackStatusDeclined"
                                        type="text"
                                        label="Declined"
                                        placeholder="comma-separated values, e.g. rejected, cancelled"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Box>
                                <Box p={1} mt={1}>
                                    <Tooltip disableFocusListener
                                             className={classes?.toolTipHelp}
                                             title="A declined conversion is classified as not approved. Not paid unsuccessful event on your offer. Payout will not be added.">
                                        <Info color={"primary"} fontSize={"small"}/>
                                    </Tooltip>
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="row" flexWrap="nowrap" className={classes?.statusInput}
                                 mt={1}>
                                <Box flexGrow={1}>
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        error={!!errors.postbackStatusRefund}
                                        {...register("postbackStatusRefund", {maxLength: 250})}
                                        name="postbackStatusRefund"
                                        type="text"
                                        label="Refund"
                                        placeholder="comma-separated values, e.g. compensate, return"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Box>
                                <Box p={1} mt={1}>
                                    <Tooltip disableFocusListener
                                             className={classes?.toolTipHelp}
                                             title="A conversion with this status will be marked as refund and conversion payout will be subtracted from the revenue.">
                                        <Info color={"primary"} fontSize={"small"}/>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Box>
                    }

                    <Box display="flex" flexWrap="wrap" flexDirection="column" mt={1}>
                        <Box>
                            <Controller
                                name="postbackOnlyWhiteIps"
                                control={control}
                                render={({field: {onChange, value, ...props}}) => (
                                    renderSwitchButton(
                                        onChange, value,
                                        "Only accept postback from white-listed IPs",
                                        "This option enables an IP filter for postbacks from this network. The conversion data will be accepted only from the listed IP addresses."
                                    )
                                )}
                                defaultValue={false}
                            />
                        </Box>
                    </Box>

                    {watch("postbackOnlyWhiteIps") &&
                        <TextareaAutosize
                            name="postbackWhiteIps"
                            className={classes?.textArea}
                            minRows={3}
                            {...register("postbackWhiteIps")}
                            placeholder="Comma-separated IPs 11.12.13.14, 21.22.23.244"
                        />
                    }

                    <input type="submit" hidden="hidden"/>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit" variant="outlined">
                    Cancel
                </Button>
                {loading
                    ? <Spinner/>
                    : <Button onClick={handleSubmit(onSubmit)} color="primary" variant="contained">
                        Save & Close
                    </Button>
                }
            </DialogActions>
        </>
    );
};