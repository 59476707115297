import {
    Box,
    Button, Dialog,
    DialogActions,
    DialogContent, DialogTitle,
    List,
    TextField, useMediaQuery, useTheme, Link
} from "@mui/material";
import {Check} from "@mui/icons-material";
import {integrationActions} from "../../../../store/actions/integrationAction";
import {connect} from "react-redux";
import React, {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import Spinner from "../../../spinner/Spinner";
import {integrationService} from "../../../../service/integrationService";
import {alertActions} from "../../../../store/actions/alertActions";
import CustomConversionEventSelect from "../../../input/CustomConversionEventSelect";
import ReactHookFormSelect from "../../../input/ReactHookFormSelect";
import {customEventServices} from "../../../../service/customEventService";
import {CustomListItem, CustomListItemIcon, CustomListItemText} from "../RevContentIntegration";

function RsocSystem1Integration(props) {
    const theme = useTheme();
    const {integration, create, error, update, alertState} = props;

    const integrationIdParam = props?.match?.params?.id;

    const {register, handleSubmit, setValue, control, formState: {errors}} = useForm();

    const [loading, setLoading] = useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleOnSubmit = useCallback((data) => {

        if (data?.customConversionEventId?.value) {
            data.customConversionEventId = data?.customConversionEventId?.value.toString();
        }

        if (integrationIdParam > 0) {
            update(integrationIdParam, data);
        } else {
            create(data);
        }
    });

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        props.reset();
        props.history.push({pathname: "/settings/integrations", state: {from: props.location}});
    }, [props]);

    useEffect(() => {
        async function find(id): void {
            setLoading(true);
            await integrationService.findIntegration(id).then((result) => {
                setValue("name", result?.networkName);
                result?.integrationCredentials?.forEach((c) => {
                    if (c?.keyName === "system1_api_key") {
                        setValue("apiKey", c?.keyValue);
                    }
                    if (c?.keyName === "system1_currency") {
                        setValue("currency", c?.keyValue);
                    }
                    if (c?.keyName === "system1_custom_conversion_event_id") {
                        if (c?.keyValue !== "") {
                            customEventServices.find(parseInt(c?.keyValue)).then((ce) => {
                                setValue("customConversionEventId", {"label": ce?.name, "value": ce?.id});
                            }).catch((e) => {
                                error(e?.response?.data?.error);
                            });
                        }
                    }
                });
                setLoading(false);
            }).catch((e) => {
                setLoading(false);
                error(e?.response?.data?.error);
                props.history.push({pathname: "/settings/integrations", state: {from: props.location}});
            });
        }

        if (integrationIdParam > 0) {
            find(integrationIdParam);
        }
    }, [create, error, integrationIdParam, props.history, props.location, setValue]);

    useEffect(() => {
        const listener = event => {
            if (event?.code === "Enter" || event?.code === "NumpadEnter") {
                event.preventDefault();
                handleSubmit(handleOnSubmit)();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [handleOnSubmit, handleSubmit]);

    useEffect(() => {
        if (alertState?.successMsg !== "") {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alertState?.successMsg, handleClose])

    return (

        <Dialog open={true}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                maxWidth={"sm"}
                aria-labelledby="system1-integration-form"
        >
            <DialogTitle sx={{textAlign: "center", paddingBottom: 0}}>
                <img src="/assets/images/affiliate-networks/system1.svg" alt="System1 integration" width={130}
                     style={{
                         backgroundColor: theme.palette.background.default,
                         borderRadius: "4px",
                         padding: "8px"
                     }}
                />
            </DialogTitle>
            <DialogContent>
                <Box sx={{
                    paddingTop: "8px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    paddingBottom: "15px"
                }}>

                    {(integration.loading.integration || loading) && <Spinner size={32} overComponentBox={true}/>}

                    <form onSubmit={handleSubmit(handleOnSubmit)} autoComplete={"off"}>
                        <Box display="flex" flexWrap={"wrap"}>
                            <Box p={1} sx={{color: theme.palette.text.disabledLight}}>
                                <p>
                                    Please see our integration
                                    guide <Link target="_blank" underline="hover" rel="noreferrer"
                                                href="https://skro.eu/docs/integrations/system1-rsoc-integration"
                                >
                                    here
                                </Link>
                                </p>
                            </Box>
                            <Box p={1} width="100%">
                                <TextField
                                    error={!!errors.name}
                                    variant="outlined"
                                    margin="dense"
                                    id="name"
                                    label="Integration Name*"
                                    {...register("name", {required: true, maxLength: 255, minLength: 2})}
                                    name="name"
                                    size="small"
                                    type="text"
                                    placeholder="Enter your integration name"
                                    fullWidth={true}
                                    defaultValue="RSOC System1"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                            <Box p={1} width="100%">
                                <TextField
                                    error={!!errors.apiKey}
                                    variant="outlined"
                                    margin="dense"
                                    id="apiKey"
                                    size="small"
                                    label="API Key*"
                                    {...register("apiKey", {required: true, maxLength: 255, minLength: 2})}
                                    name="apiKey"
                                    type="text"
                                    placeholder="Enter your API Key"
                                    fullWidth={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                            <Box p={1} width="100%">
                                <ReactHookFormSelect
                                    rules={{required: true}}
                                    name="currency"
                                    control={control}
                                    setValue={setValue}
                                    label="System1 Account Currency*"
                                    placeholder="Your System1 account currency"
                                    defaultValue="EUR"
                                    options={[
                                        {"label": "EUR", "value": "EUR"},
                                        {"label": "USD", "value": "USD"},
                                        {"label": "GBP", "value": "GBP"}
                                    ]}
                                />
                            </Box>
                            <Box p={1} width="100%">
                                <CustomConversionEventSelect control={control} errors={errors}/>
                            </Box>
                        </Box>
                    </form>

                    <Box display="flex" flexDirection="row">
                        <Box p={1}>
                            <List>
                                <CustomListItem>
                                    <CustomListItemIcon>
                                        <Check color={"primary"}/>
                                    </CustomListItemIcon>
                                    <CustomListItemText>
                                        Revenue updates every hour.
                                    </CustomListItemText>
                                </CustomListItem>
                                <CustomListItem>
                                    <CustomListItemIcon>
                                        <Check color={"primary"}/>
                                    </CustomListItemIcon>
                                    <CustomListItemText>
                                        Revenue updates for the previous 2 days once a day.
                                    </CustomListItemText>
                                </CustomListItem>
                            </List>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box display="flex" flexWrap="noWrap">
                    <Box mr={2}>
                        <Button onClick={handleClose} color="inherit" variant="outlined">
                            {integrationIdParam > 0 ? "Cancel" : "Close"}
                        </Button>
                    </Box>
                    <Box>
                        <Button onClick={handleSubmit(handleOnSubmit)} color="primary" variant="contained">
                            Save & Close
                        </Button>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        integration: state.integration,
        alertState: state.alert
    };
};

const mapDispatchToProps = {
    reset: integrationActions.reset,
    create: integrationActions.createRsocSystem1Integration,
    update: integrationActions.updateRsocSystem1Integration,
    error: alertActions.error
};

export default connect(mapStateToProps, mapDispatchToProps)(RsocSystem1Integration);