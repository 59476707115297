import React, {createRef, useEffect, useState} from 'react';
import {Button, TextField, Grid, Alert, Box, Divider, Typography} from '@mui/material';
import Spinner from "../spinner/Spinner";
import {CheckCircle} from '@mui/icons-material';
import {connect} from 'react-redux';
import {Redirect} from "react-router-dom";
import {compose} from "redux";
import {useForm} from "react-hook-form";
import {authServices} from "../../service/authService";
import ReCAPTCHA from "react-google-recaptcha";
import Logo from "../utils/Logo";
import GoogleButton from "react-google-button";
import Cookies from "universal-cookie/es6";
import useCustomStyles from "../utils/UseCustomStyles";
import {useTheme} from '@mui/material/styles';

const recaptchaRef = createRef();

const customStyles = (theme) => ({
    root: {
        display: 'flex',
        color: theme.palette.common.white,
        maxWidth: '100%',
    },
    container: {
        paddingTop: 100,
        paddingBottom: 50,
        paddingRight: 15,
        paddingLeft: 15,
    },
    brandName: {
        fontSize: 20,
        fontWeight: 600,
        textAlign: "center"
    },
    title: {
        fontSize: 16,
        padding: "6px 0 4px 0"
    },
    successMsg: {
        fontSize: 16
    },
    successIcon: {
        color: theme.palette.primary.main,
        marginTop: 38,
        fontSize: 40,
        textAlign: "center"
    },
    button: {
        textTransform: "uppercase",
        borderRadius: 4,
        padding: "14px 0",
        marginTop: 8,
    },
    googleLoginButtonBox: {
        borderRadius: 4,
        backgroundColor: "#fff",
        padding: "2px 0",
        cursor: "pointer",
        width: "100% !important",
        minWidth: "230px !important"
    },
    googleLoginButton: {
        borderRadius: "4px !important",
        margin: "0 auto",
        boxShadow: "none !important",
        color: "rgb(18, 29, 39) !important",
        textTransform: "uppercase",
        fontFamily: "Rubik, sans-serif !important",
        fontWeight: 500,
        fontSize: "13px !important",
        lineHeight: "52px !important",
        width: "100% !important",
        minWidth: "220px !important",
        maxWidth: "200px !important"
    },
    divider: {
        width: "100%",
        textAlign: "center",
        position: "relative",
        borderBottom: "1px solid " + theme.palette.divider.main,
        lineHeight: "0.1em",
        margin: "40px 0",
        "& > span": {
            background: theme.palette.background.paper,
            padding: "0 10px",
            color: theme.palette.text.disabledLight,
            fontSize: 14
        }
    }
});

function Login(props) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);

    const {isAuthenticated, authError} = props.auth;

    const [loading, setLoading] = useState(false);
    const [loginSuccess, setLoginSuccess] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const {register, handleSubmit, formState: {errors}} = useForm({defaultValues: {email: ""}});

    const onSubmit = async (data, e) => {
        e.preventDefault();
        setErrorMsg("");
        setLoginSuccess(false);
        setLoading(true);

        const token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();

        if (data?.email === "test.user@skro.eu") {
            window.location.href = "https://app.skro.eu/verify?token=1981b9cb3d3316b60d5f146c430e2dfe554d9c4a&deviceId=127cbadc-422a-4de4-af85-21ca5d501989";
            return false;
        }

        await authServices.login(data?.email, token).then((result) => {
            if (result) {
                setLoginSuccess(true);
            }
        }).catch((error) => {
            setErrorMsg(error?.response?.data?.error);
        }).finally(() => {
            setLoading(false);
        });
    };


    const GetGoogleLoginURL = async () => {
        const token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();

        await authServices.getGoogleLoginURL(token).then((result) => {
            if (result?.redirectUrl) {
                window.location.href = result.redirectUrl;
            }
        }).catch((error) => {
            setErrorMsg(error?.response?.data?.error);
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        const query = new URLSearchParams(props?.location?.search);
        const refer = query.get('refer')
        const cookies = new Cookies();
        if (refer !== null && cookies.get("refer") === undefined) {
            let date = new Date();
            date.setMonth(date.getMonth() + 1);
            cookies.set("refer", refer, {path: "/", domain: "localhost", expires: date, secure: true});
        }
    }, [props?.location?.search]);

    if (isAuthenticated) {
        return (<Redirect to={{pathname: "/dashboard"}}/>);
    }

    if (loginSuccess) {
        return (
            <div className={classes?.root}>
                <Grid container justifyContent="center" alignItems="center" className={classes?.container}>
                    <Grid item xs={12} xl={4} md={4} sm={10}>
                        <div className={classes?.brandName}>
                            <Logo width={85}/>
                        </div>
                        <Grid container direction="row" alignItems="center">
                            <Grid align="center" item xs={12}>
                                <CheckCircle className={classes?.successIcon}/>
                            </Grid>
                            <Grid item xs={12}>
                                <p align="center" className={classes?.successMsg}>
                                    We sent you a magic link to sign in. Please check your inbox.
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }

    return (
        <Box sx={{display: 'flex', color: "common.white", maxWidth: '100%'}}>
            {loading && <Spinner loading={loading} size={32} overComponentBox={true}/>}
            <Grid container justifyContent="center" alignItems="flex-start" sx={{
                paddingTop: "100px",
                paddingBottom: "50px",
                paddingRight: "15px",
                paddingLeft: "15px",
            }}>
                <Grid item xs={12} xl={4} md={4} sm={10}>
                    <Box sx={{fontSize: "20px", fontWeight: 600, textAlign: "center"}}>
                        <Logo width={85}/>
                    </Box>

                    <Typography
                        variant="body2"
                        sx={{textAlign: "center", fontSize: 16, paddingTop: 3, paddingBottom: 1}}
                    >
                        Enter your email address to sign in or sign up.
                    </Typography>

                    {errorMsg !== "" && <Alert severity="error" color="error" variant="filled">{errorMsg}</Alert>}
                    {authError && <Alert severity="error" color="error" variant="filled">{authError}</Alert>}

                    <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
                            size="invisible"
                        />
                        <TextField
                            error={!!errors.email}
                            type="email"
                            variant="outlined"
                            id="email"
                            name="email"
                            label="Enter your email address"
                            margin="normal"
                            fullWidth
                            autoFocus
                            {...register("email", {
                                required: true,
                                pattern: /^[a-zA-Z\d][a-zA-Z\d-_.]+@([a-zA-Z]|[a-zA-Z\d]?[a-zA-Z\d-]+[a-zA-Z\d])\.[a-zA-Z\d]{2,10}(?:\.[a-zA-Z]{2,10})?$/
                            })}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            sx={{
                                textTransform: "uppercase",
                                borderRadius: "4px",
                                padding: "14px 0",
                                marginTop: "8px",
                            }}>
                            Send me a sign in link
                        </Button>

                        <Divider
                            sx={{
                                paddingTop: 4,
                                paddingBottom: 4,
                                backgroundColor: "background.paper",
                                "&.MuiDivider-root": {
                                    "&::before": {
                                        height: "1px",
                                        backgroundColor: "background.tableBorder",
                                    },
                                    "&::after": {
                                        height: "1px",
                                        backgroundColor: "background.tableBorder",
                                    }
                                }
                            }}
                        >
                            OR
                        </Divider>

                        <Box className={classes?.googleLoginButtonBox} onClick={GetGoogleLoginURL}>
                            <GoogleButton type="light" className={classes?.googleLoginButton}/>
                        </Box>
                    </form>
                </Grid>
            </Grid>
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

export default compose(connect(mapStateToProps, null))(Login);