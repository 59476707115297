import React, {useEffect, useState} from 'react';
import {Controller} from "react-hook-form";
import debounce from "lodash/debounce";
import {CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";
import {Autocomplete, Box, Checkbox, Chip, TextField} from "@mui/material";
import {customEventServices} from "../../service/customEventService";

const icon = <CheckBoxOutlineBlank fontSize="small"/>;
const checkedIcon = <CheckBox fontSize="small"/>;

function ReactHookCustomConversionMultiSelect(
    {
        control,
        inputLabel = "Select custom conversions",
        inputName = "selectedCustomConversions",
        updateOnChange = () => null,
        getValues,
        inputLabelShrink = true,
        errors
    }
) {

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData(): void {
            try {
                setLoading(true);
                const response = await customEventServices.fetch({});
                setOptions(
                    response?.map(item => {
                        return {
                            id: item.id,
                            name: item?.name
                        };
                    })
                );
            } catch (e) {
                console.log("Error: ", e?.response?.data?.error);
            } finally {
                setLoading(false);
            }
        }

        if (open && (search.length === 0 || search.length > 0)) {
            fetchData();
        }

    }, [search, open]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
            setSearch("");
        }
    }, [open]);

    const handleSearch = debounce(searchTerm => {
        setSearch(searchTerm);
    }, 800);

    return (
        <Controller
            render={({field: {onChange, value, ...props}}) => (
                <Autocomplete
                    multiple
                    disableCloseOnSelect={true}
                    id="multi-custom-conversions-select"
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => option?.name === value?.name}
                    getOptionLabel={(option) => option?.name}
                    options={options}
                    loading={loading}
                    value={Array.isArray(value) ? value : []}
                    renderOption={(props, option, {selected, index}) => (
                        <li {...props} key={index}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                sx={{marginRight: "8px", padding: "2px"}}
                                checked={selected}
                                color="primary"
                            />
                            {option?.name}
                        </li>
                    )}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => {
                            const {key, ...rest} = getTagProps({index});
                            return (
                                <Chip
                                    key={key} variant="outlined" color="primary" size="small"
                                    label={option?.name} {...rest}
                                />);
                        })
                    }
                    renderInput={params => (
                        <Box display="flex" flexDirection="row" flexWrap="noWrap">
                            <Box flexGrow={1}>
                                <TextField
                                    {...params}
                                    error={!!errors.selectedCustomConversions}
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    placeholder={getValues("selectedCustomConversions")?.length === 0 ? "Select custom conversions" : ""}
                                    label={inputLabel}
                                    onChange={e => handleSearch(e.target.value)}
                                    InputLabelProps={{
                                        shrink: inputLabelShrink
                                    }}
                                />
                            </Box>
                        </Box>
                    )}
                    onChange={(e, data) => {
                        onChange(data);
                        updateOnChange();
                    }}
                    {...props}
                />
            )}
            rules={{required: true}}
            defaultValue={[]}
            name={inputName}
            control={control}
            onChange={([, data]) => data}
        />
    );
}

export default ReactHookCustomConversionMultiSelect;